import {
  ErrorMessages,
  InputGroup,
  RadioBox,
  RadioBoxContainer,
  getErrorMessages,
} from '@selectra-it/selectra-ui'
import {
  useEffect,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'
import {
  UseFormReturn,
} from 'react-hook-form'

import useGetRadioStatus from '@hooks/useGetRadioStatus'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  Beneficiary,
  beneficiaryHasChildren,
  BeneficiaryIcons, numberOfAdults,
} from '@root/domain/Beneficiary'

const items = [
  {
    id: Beneficiary.PRINCIPAL,
    label: 'Un adulte',
    value: Beneficiary.PRINCIPAL,
  },
  {
    id: Beneficiary.PRINCIPAL_AND_CHILDREN,
    label: 'Un adulte + enfant(s)',
    value: Beneficiary.PRINCIPAL_AND_CHILDREN,
  },
  {
    id: Beneficiary.PRINCIPAL_AND_SPOUSE,
    label: 'Un couple',
    value: Beneficiary.PRINCIPAL_AND_SPOUSE,
  },
  {
    id: Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN,
    label: 'Un couple + enfant(s)',
    value: Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN,
  }
]

const BeneficiaryComponent = ({
  register, watch, setValue, resetField, formState,
}: UseFormReturn<WizardFormData>) => {
  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const {
    errors,
  } = formState

  const BeneficiaryStatus = useGetRadioStatus(errors.beneficiary?.type)

  const inputErrors = getErrorMessages(
    {
      ...ErrorMessages,
      required: t('validation:validation.form.mustSelectBeneficiary'),
    },
    errors.beneficiary?.type
  )

  const beneficiary = watch('beneficiary')

  useEffect(() => {
    if (!beneficiaryHasChildren[beneficiary]) {
      setValue('children', [])
      setValue('amountOfChildren', '0')
    } else {
      setValue('amountOfChildren', '1')
    }

    if (numberOfAdults[beneficiary] === 1) {
      resetField('adults.1')
    }
  }, [beneficiary, setValue, resetField])

  return (
    <div className="flex flex-col gap-6">
      <InputGroup errors={inputErrors}>
        <RadioBoxContainer>
          {items.map(({
            id,
            label,
            value,
          }) => (
            <RadioBox
              key={id}
              id={id}
              item={{
                label,
                value,
                icon: BeneficiaryIcons[id],
              }}
              status={BeneficiaryStatus}
              {...register('beneficiary', {
                required: true,
              })}
              dataCy={value}
            />
          ))}
        </RadioBoxContainer>
      </InputGroup>
    </div>
  )
}

export default BeneficiaryComponent
