import {
  useLocation,
  useParams,
} from 'react-router-dom'

import useGetOffersByEstimationId from './offers/useGetOffersByEstimationId'

const useIsOfferPage = () => {
  const {
    pathname,
  } = useLocation()

  const params = useParams()

  const estimationId = params.estimationId ?? ''

  const {
    isSuccess,
  } = useGetOffersByEstimationId(estimationId)

  return {
    isOnePagePage: pathname.includes('/recherche'),
    isOfferPage: pathname.includes('/offre/'),
    isOfferPageLoaded: isSuccess,
  }
}

export default useIsOfferPage
