import {
  v4 as uuidv4,
} from 'uuid'
import {
  ButtonType,
  Caption,
  TextVariant,
} from '@selectra-it/selectra-ui'
import {
  FC,
  useEffect,
  useRef,
} from 'react'
import {
  useParams,
} from 'react-router-dom'

import {
  useAppSelector,
} from '@root/store/Reducer'
import dayjs from '@root/plugins/day'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import {
  selectOfferById,
} from '@root/services/offers/selectors'
import {
  useCreateMailingMutation,
} from '@root/services/mailing'
import useGetUserFromEstimationIdRouteParam from '@hooks/offers/useGetUserFromEstimationIdRouteParam'
import {
  insurerLogosAbsolutePath,
  InsurerMerOption,
} from '@root/domain/Insurer'
import useGetOffersByEstimationId from '@hooks/offers/useGetOffersByEstimationId'
import {
  priceInCentsToString,
} from '@root/util/formatting'
import {
  useCreateOfferMerMutation,
} from '@root/services/offers'

import {
  MerTypeActionMap,
  MerTypeMap,
} from './v2'
import Divider from './v2/Divider'
import MailingLoading from './MailingLoading'
import MailingMessage from './Mailing/MailingMessage'
import MerActionMessage from './MerActionMessage'

const Mer: FC = () => {
  const {
    offerId, estimationId,
  } = useParams()

  const {
    fetchUser: {
      data: user,
    },
  } = useGetUserFromEstimationIdRouteParam()

  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state))
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? ''))
  const {
    data,
  } = useGetOffersByEstimationId(estimationId as string)
  const estimation = data?.estimation

  const [createMailing, {
    isLoading, isError, isSuccess, error, isUninitialized,
  }] = useCreateMailingMutation()

  const hasRunMailing = useRef<boolean>(false)
  const hasRunCreateMer = useRef<boolean>(false)

  useEffect(() => {
    if (user && offer && insurer && estimation) {
      if (hasRunMailing.current) return

      hasRunMailing.current = true

      createMailing({
        id: uuidv4(),
        email: user?.email ?? '',
        type: 'health_comparator_offer_sending',
        payload: {
          offerProvider: insurer?.name,
          offerProviderLogoUrl: `${insurerLogosAbsolutePath[insurer.id]}`,
          offerTitle: offer.title,
          offerDate: dayjs().format('DD/MM/YYYY'),
          offerPrice: priceInCentsToString(offer.monthlyPrice),
          selectraCallbackRequest: 'selectraCallbackRequest',
          cgvLink: `${import.meta.env.VITE_BASE_URL}/api/documents/${offer.documents.find(doc => doc.type === 'GENERAL_CONDITIONS')?.id}`,
          ipidLink: `${import.meta.env.VITE_BASE_URL}/api/documents/${offer.documents.find(doc => doc.type === 'IPID')?.id}`,
          guarantiesLink: `${import.meta.env.VITE_BASE_URL}/api/documents/${offer.documents.find(doc => doc.type === 'GUARANTEES')?.id}`,
          offerPageLink: `${window.location.origin}/offre/${estimation.id}`,
          offerMerLink: `${window.location.origin}/offre/${estimation.id}/${offer.id}/mer`,
        },
        unique_id: `${offer?.id}-${user?.email}`,
      })
    }
  }, [createMailing, offer, user, insurer, estimation])

  const [createMer, {
    isLoading: isCreateMerLoading,
    isSuccess: isMerCreateSuccess,
  }] = useCreateOfferMerMutation()

  useEffect(() => {
    if (insurer?.merGatewayPrimary === InsurerMerOption.CALLBACK_REQUEST_SELECTRA && insurer?.merGatewaySecondary === null && offer) {
      if (hasRunCreateMer.current) return

      hasRunCreateMer.current = true

      createMer({
        offerId: offer?.id ?? '',
        id: uuidv4(),
        type: InsurerMerOption.CALLBACK_REQUEST_SELECTRA,
        callback_date: {
          isImmediate: true,
          date: null,
        },
      })
    }
  }, [createMer, hasRunCreateMer, insurer, offer, isMerCreateSuccess])

  if (!offer || !insurer || !user) {
    return null
  }

  if (isLoading || isUninitialized || isCreateMerLoading) return <MailingLoading />

  const PrimaryMerOption = MerTypeMap[insurer.merGatewayPrimary]
  const SecondaryMerOption = insurer.merGatewaySecondary ? MerTypeMap[insurer.merGatewaySecondary] : null
  const PrimaryMerOptionAction = MerTypeActionMap[insurer.merGatewayPrimary]

  if (!insurer.merGatewaySecondary) {
    return (
      <div className="flex flex-col gap-4  md:p-2">
        <MailingMessage isSuccess={isSuccess} isError={isError}
          email={user?.email}
          isAlreadyCreated={!!(error && 'status' in error && error.status && error.status === 409)}
        />

        {insurer.merGatewayPrimary === InsurerMerOption.CALLBACK_REQUEST_SELECTRA && (
          <div className='text-[#242525]'>
            <Caption variant={TextVariant.BOLD}>Un expert en assurance va vous rappeler dans quelques instants pour analyser votre devis et poser vos questions.</Caption>
          </div>
        )}

        {insurer.merGatewayPrimary !== InsurerMerOption.CALLBACK_REQUEST_SELECTRA && (
          <>
            <MerActionMessage primary={insurer.merGatewayPrimary} secondary={insurer.merGatewaySecondary} />

            <PrimaryMerOptionAction type={ButtonType.PRIMARY} offerId={offer.id}
              estimationId={offer.estimationId}
            />
          </>
        )}
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4  md:p-2">

      <MailingMessage isSuccess={isSuccess} isError={isError}
        email={user?.email}
        isAlreadyCreated={!!(error && 'status' in error && error.status && error.status === 409)}
      />

      <MerActionMessage primary={insurer.merGatewayPrimary} secondary={insurer.merGatewaySecondary} />

      <PrimaryMerOption type={ButtonType.PRIMARY} offerId={offer.id}
        estimationId={offer.estimationId}
      />

      {SecondaryMerOption && (
        <>
          <Divider />
          <SecondaryMerOption type={ButtonType.SECONDARY} offerId={offer.id}
            estimationId={offer.estimationId}
          />
        </>
      )}
    </div>
  )
}

export default Mer
