import {
  BEST_PRICE,
  BEST_QUALITY,
  BEST_QUALITY_PRICE,
  BEST_RATING,
  FREE_TRIAL_1_MONTH,
  Tags,
} from '@root/services/offers'

import useGetOffersByEstimationId from './useGetOffersByEstimationId'

const tagPriority = [
  FREE_TRIAL_1_MONTH,
  BEST_RATING,
  BEST_PRICE
]

interface ColorShape {
  background: string,
  border: string,
}

type Shape = {
  [key in keyof Tags]: ColorShape
};

export const tagColors: Shape = {
  [BEST_PRICE]: {
    background: 'bg-primary-400',
    border: 'md:border-primary-400',
  },
  [BEST_RATING]: {
    background: 'bg-[#864906]',
    border: 'md:border-[#864906]',
  },
  [FREE_TRIAL_1_MONTH]: {
    background: 'bg-success-400',
    border: 'md:border-success-400',
  },
  [BEST_QUALITY_PRICE]: {
    background: 'bg-[#864906]',
    border: 'md:border-[#864906]',
  },
  [BEST_QUALITY]: {
    background: 'bg-[#864906]',
    border: 'md:border-[#864906]',
  },
}

export const useGetEstimationTags = (estimationId: string, offerId: string) => {
  const {
    data,
  } = useGetOffersByEstimationId(estimationId)

  if (!data) {
    return {
      tag: null,
    }
  }

  const tags = Object.keys(data.tags).filter(key => {
    const value = data.tags[key as keyof Tags]
    const values = Array.isArray(value) ? value : [value]

    return values.indexOf(offerId) >= 0
  })

  const priorityTag = tags.sort((a, b) => {
    return tagPriority.indexOf(a) - tagPriority.indexOf(b)
  }).at(0)

  return {
    tag: priorityTag as keyof Tags,
  }
}
