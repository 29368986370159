import {
  Caption, Heading, HeadingVariant, TextVariant,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'
import {
  Link,
} from 'react-router-dom'

import Icon from '@components/Icon'

interface MailingMessageProps {
  isSuccess: boolean;
  isError: boolean;
  isAlreadyCreated?: boolean;
  email: string;
}

const MailingMessage: FC<MailingMessageProps> = ({
  isSuccess,
  isError,
  isAlreadyCreated,
  email,
}) => {
  if (isAlreadyCreated) {
    return null
  }

  if (isError) {
    return (
      <div className="flex flex-col gap-2 items-center self-stretch">
        <div className='flex gap-1 items-center self-stretch'>
          <Icon name='cancel circle' size={24}
            className='fill-danger-400'
          />
          <Heading variant={HeadingVariant.H5}>Oups ! Nous n’avons pas pu envoyer l’e-mail.</Heading>
        </div>
        <div className='w-full text-[#343535] flex flex-col gap-2'>
          <Caption variant={TextVariant.REGULAR}>
            Vérifiez que votre adresse <b>{email}</b> est correcte. <Link to={'/recherche'} className='text-primary-400'><u>Modifier mon email</u></Link>
          </Caption>
          <Caption variant={TextVariant.REGULAR}>
            En attendant, vous pouvez toujours <b>être rappelé par un conseiller</b> pour analyser votre devis <b>et poser vos questions.</b>
          </Caption>
        </div>
      </div>
    )
  }

  if (isSuccess) {
    return (
      <div className="flex flex-col gap-2 items-center self-stretch">
        <div className='flex gap-1 items-center self-stretch'>
          <Icon name='circle-check' size={24}
            className='fill-success-400'
          />
          <Heading variant={HeadingVariant.H5}>Devis envoyé !</Heading>
        </div>
        <div className='w-full text-[#343535]'>
          <Caption variant={TextVariant.REGULAR}>
          Vous venez de recevoir votre devis à {email}.
          </Caption>
        </div>
      </div>
    )
  }
}

export default MailingMessage
