import {
  Badge, FootNote, TextVariant, Variant,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'

import {
  BEST_PRICE, BEST_QUALITY, BEST_QUALITY_PRICE, BEST_RATING, FREE_TRIAL_1_MONTH, Tags,
} from '@root/services/offers'

interface TagProps {
  tag: keyof Tags
}

const TagVariants: Record<keyof Tags, Variant> = {
  [BEST_PRICE]: Variant.NEUTRAL,
  [BEST_QUALITY]: Variant.SECONDARY_LIGHT,
  [BEST_QUALITY_PRICE]: Variant.CAUTION_LIGHT,
  [BEST_RATING]: Variant.CAUTION_LIGHT,
  [FREE_TRIAL_1_MONTH]: Variant.SUCCESS_LIGHT,
}

const Tag: FC<TagProps> = ({
  tag,
}) => {
  const {
    t,
  } = useTranslation(['offers'])

  const variant = TagVariants[tag]
  const label = t(`offers:tags.${tag}`)

  return (
    <FootNote variant={TextVariant.BOLD}>
      <Badge variant={variant} label={label} />
    </FootNote>
  )
}

export default Tag
