import {
  FC,
} from 'react'
import {
  useParams,
} from 'react-router-dom'

import useGetUserFromEstimationIdRouteParam from '@hooks/offers/useGetUserFromEstimationIdRouteParam'
import {
  InsurerMerOption,
} from '@root/domain/Insurer'

import CallbackForm from '../CallbackForm'

const RequestCallbackAction: FC = () => {
  const {
    offerId, estimationId,
  } = useParams()

  const {
    fetchUser: {
      data: user,
    },
  } = useGetUserFromEstimationIdRouteParam()

  if (!user) {
    return null
  }

  return (
    <>
      {/* <Heading variant={HeadingVariant.H5}>
        {t('offers:mer.request_callback.title')}
      </Heading> */}

      {/* <Text variant={TextVariant.REGULAR}>
        {t('offers:mer.request_callback.description')}
      </Text> */}

      <CallbackForm offerId={offerId as string} estimationId={estimationId as string}
        type={InsurerMerOption.CALLBACK_REQUEST}
        user={user}
      />
    </>
  )
}

export default RequestCallbackAction
