import {
  FC,
} from 'react'
import {
  Heading, HeadingVariant,
} from '@selectra-it/selectra-ui'
import {
  useParams,
} from 'react-router-dom'

import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectOfferById,
} from '@root/services/offers/selectors'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import Price from '@components/offers/Price'
import {
  insurerLogos,
} from '@root/domain/Insurer'

const MerHeader: FC = () => {
  const {
    offerId, estimationId,
  } = useParams()

  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state))
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? ''))

  if (!offer || !insurer) {
    return null
  }

  const Icon = insurerLogos[insurer.id]

  return (
    <div className="flex gap-2">
      <div className="flex grow flex-col gap-2">
        <div>
          {typeof Icon === 'string'
            ? <img src={Icon} className='h-10'/>
            : <Icon height={40} />}
        </div>
        <Heading variant={HeadingVariant.H5}>
          {offer.title}
        </Heading>
      </div>
      <Price price={offer.monthlyPrice} />
    </div>
  )
}

export default MerHeader
