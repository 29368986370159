import {
  Caption,
  Heading, HeadingVariant,
  Text,
  TextVariant,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'

interface PriceProps {
  price: number,
  withDecimals?: boolean,
}

const Price = ({
  price,
  withDecimals = true,
}: PriceProps) => {
  const {
    t,
  } = useTranslation(['common'])

  const euros = Math.floor(price / 100)
  const cents = (price % 100).toString().padStart(2, '0')

  return (
    <div className="justify-start items-start inline-flex text-success-400">
      <div className="text-right">
        <Heading variant={HeadingVariant.H1}>{euros}</Heading>
      </div>
      <div className="flex-col justify-start items-start inline-flex gap-1">
        <div className="w-[13px] h-4">
          <Text variant={TextVariant.BOLD}>{withDecimals && cents}€</Text>
        </div>
        <div className="text-right">
          <Caption variant={TextVariant.REGULAR}>/{t('common:month')}</Caption>
        </div>
      </div>
    </div>
  )
}

export default Price
