import {
  Button,
  ButtonSize,
  ButtonType,
  Heading, HeadingVariant,
} from '@selectra-it/selectra-ui'
import {
  useDispatch,
} from 'react-redux'

import {
  HELP_MODAL,
  OFFER_FILTER_MODAL, toggleModal,
} from '@root/services/modalSlice'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectOfferFilters,
} from '@root/services/filterSlice'
import Icon from '@components/Icon'

import AlternativeMedicine from './OfferItem/AlternativeMedicine'
import Level from './OfferItem/Level'

const OfferFilters = () => {
  const dispatch = useDispatch()

  const {
    alternativeMedicine,
    dentalLevel: dental,
    opticalLevel: optical,
    hospitalLevel: hospitalization,
    regularHealthLevel: consultation,
  } = useAppSelector(selectOfferFilters)

  return (
    <div className="flex flex-col items-start gap-4 rounded-2xl border border-neutral-100 bg-white p-6">
      <div className='flex w-full flex-col gap-[10px]'>
        <Heading variant={HeadingVariant.H6}>Votre choix</Heading>
        <ul className='mb-2 flex flex-col gap-[10px]'>
          {Object.entries({
            consultation, hospitalization, dental, optical,
          }).map(([key, level]) => {
            return level && (
              <Level
                key={key}
                level={level}
                label={key}
                isIncluded={false}
              />
            )
          })}
          <AlternativeMedicine alternativeMedicine={Boolean(alternativeMedicine)} />
        </ul>
      </div>
      <Button size={ButtonSize.SMALL} variant={ButtonType.PRIMARY}
        onClick={() => {
          dispatch(toggleModal(OFFER_FILTER_MODAL))
        }}
        iconLeft={() => <Icon name='edit' size={16} />}
        label='Modifier mes niveaux'
        pill
      />
      <Button size={ButtonSize.SMALL} variant={ButtonType.LIGHT}
        label='Besoin de conseil ?'
        pill
        onClick={() => {
          dispatch(toggleModal(HELP_MODAL))
        }}
        hasBorderColor={true}
      />
    </div>
  )
}

export default OfferFilters
