import {
  Heading,
  HeadingVariant,
  Text, TextVariant,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'

import Icon from '@components/Icon'
import {
  LeadsourceOption, PhoneNumbers,
} from '@root/domain/crm/Record'

import CallbackBox from '../CallbackModal/CallbackBox'

interface InformationProps {
  setLocation: () => void;
}

const Information: FC<InformationProps> = ({
  setLocation,
}) => {
  const phoneNumber = PhoneNumbers[LeadsourceOption.COMPARATOR_HELP_CARD]

  return (
    <div className='flex flex-col items-center gap-4 overflow-y-auto text-[#343535]'>
      <div className='flex flex-wrap content-center items-center gap-2 self-stretch fill-[#808284]'>
        <Icon name='phone' size={24} />
        <Heading variant={HeadingVariant.H5}>Vous n&apos;arrivez pas à vous décider ?</Heading>
      </div>
      <div className='flex flex-col gap-6'>
        <div className='text-neutral-400 text-ju'>
          <Text variant={TextVariant.BOLD} tag='span'>Appelez l’un de nos Experts</Text><Text variant={TextVariant.REGULAR} tag='span'> en Assurance pour comparer les garanties de plusieurs devis et prendre la bonne décision.</Text><Text variant={TextVariant.BOLD} tag='span'> Service gratuit.
          </Text>
        </div>

        <CallbackBox phoneNumber={phoneNumber} ariaLabel='Faites-vous rappeler gratuitement'
          onClick={setLocation}
        />
      </div>
    </div>
  )
}

export default Information
