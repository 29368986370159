import {
  InputGroup, TextInput, getErrorMessages, ErrorMessages, CheckboxClassic,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  UseFormReturn,
} from 'react-hook-form'

import EmailValidator from '@root/util/validation/EmailValidator'
import useGetInputStatus from '@hooks/useGetInputStatus'
import PhoneNumberInput from '@components/form/PhoneNumberInput'
import {
  WizardFormData,
} from '@root/domain/Wizard'

const Email = ({
  register, formState,
}: UseFormReturn<WizardFormData>) => {
  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const {
    errors,
  } = formState

  const emailInputStatus = useGetInputStatus(errors.email?.type)
  const phoneNumberInputStatus = useGetInputStatus(errors.phoneNumber?.number?.type)

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addEmail'),
    email: 'Adresse e-mail invalide',
  }, errors.email?.type)

  const checkboxErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.toc'),
  }, errors.terms_and_conditions?.type)

  const inputProps = register('email', {
    required: true,
    validate: {
      email: EmailValidator,
    },
  })

  const {
    onChange, onBlur,
  } = inputProps

  return (
    <div className="flex flex-col gap-6">
      <InputGroup
        errors={
          getErrorMessages({
            ...ErrorMessages,
            required: t('validation:validation.form.addPhoneNumber'),
            phoneNumber: t('validation:validation.form.invalidPhoneNumber'),
          }, errors.phoneNumber?.number?.type)}
        description={t('wizard:wizard.steps.phoneNumber.description')}
      >
        <PhoneNumberInput<WizardFormData>
          label={t('wizard:wizard.steps.phoneNumber.label')}
          register={register}
          name='phoneNumber.number'
          status={phoneNumberInputStatus}
        />
        <div className="text-xs text-gray-600">
          Rassurez-vous, votre numéro de téléphone sera transmis uniquement aux assureurs avec lesquels vous souhaitez être mis en relation.
        </div>
      </InputGroup>

      <InputGroup errors={inputErrors}>
        <TextInput
          label={t('wizard:wizard.steps.email.label')}
          {...inputProps}
          onBlur={async event => {
            const newEvent = {
              ...event,
              target: {
                value: event.target.value.trim(),
                name: 'email',
              },
            }

            await onChange(newEvent)
            await onBlur(newEvent)
          }}
          status={emailInputStatus}
          dataCy="email"
          ref={e => {
            register('email').ref(e)
          }}
          autoComplete="email"
        />
        <div className="text-xs text-gray-600">
          Pas d’inquiétude, Selectra ne vous enverra pas d’e-mail non sollicité. Si vous souhaitez être mis en relation avec un assureur pour échanger sur votre demande, nous lui transmettrons votre adresse email.
        </div>
      </InputGroup>

      <div className="md:col-span-2">
        <InputGroup errors={checkboxErrors}>
          <CheckboxClassic
            {...register('terms_and_conditions', {
              required: true,
            })}
            id="terms_and_conditions"
            label="J'accepte d’être rappelé par les assureurs que je sélectionnerai à l’étape suivante en cliquant sur « Recevoir un devis »."
            error={errors.terms_and_conditions}
          />
        </InputGroup>
      </div>
    </div>
  )
}

export default Email
