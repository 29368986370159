import {
  UseFormReturn,
} from 'react-hook-form'
import {
  InputGroup, getErrorMessages, ErrorMessages,
  RadioStackedContainer,
  RadioStacked,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  useEffect,
} from 'react'

import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  CoverageOptions as options,
} from '@root/domain/Offer'
import useGetRadioStatus from '@hooks/useGetRadioStatus'
import CoverageLevelIndicator from '@components/ui/CoverageLevel'

const InsuranceLevel = ({
  register, watch, setValue, formState,
}: UseFormReturn<WizardFormData>) => {
  const {
    t,
  } = useTranslation(['wizard'])

  const {
    errors,
  } = formState

  const errorType = errors.insuranceLevel?.type

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errorType)
  const insuranceLevelStatus = useGetRadioStatus(errors.insuranceLevel?.type)

  const insuranceLevel = watch('insuranceLevel')

  useEffect(() => {
    if (insuranceLevel) {
      setValue('regularHealthLevel', insuranceLevel)
      setValue('opticalLevel', insuranceLevel)
      setValue('dentalLevel', insuranceLevel)
      setValue('hospitalLevel', insuranceLevel)
    }
  }, [insuranceLevel, setValue])

  if (!options) return null

  return (
    <InputGroup
      errors={inputErrors}
    >
      <RadioStackedContainer>
        {options.filter(option => option.id !== 'MEDIUM').map(({
          id,
          label,
          value,
        }) => (
          <RadioStacked
            key={id}
            id={id}
            item={{
              label,
              value,
              description: t(`wizard:wizard.steps.coverageLevel.${id}.insuranceLevel` as 'wizard:wizard.steps.coverageLevel.MIN.insuranceLevel'),
            }}
            status={insuranceLevelStatus}
            {...register('insuranceLevel', {
              required: true,
            })}
            dataCy={value}
            variant='free'
          >
            <div className='flex w-full items-center gap-4 self-stretch'>
              <CoverageLevelIndicator level={id} text={t(`wizard:wizard.steps.coverageLevel.${id}.insuranceLevelLabel`)} />
              <div className='flex w-full items-center gap-4 self-stretch'>
                <div className="shrink grow basis-0" dangerouslySetInnerHTML={{
                  __html: t(`wizard:wizard.steps.coverageLevel.${id}.insuranceLevel` as 'wizard:wizard.steps.coverageLevel.MIN.insuranceLevel'),
                }}
                />
              </div>
            </div>
          </RadioStacked>
        ))}
        <RadioStacked
          id={'NONE'}
          item={{
            label: 'label',
            value: '',
            description: t('wizard:wizard.steps.coverageLevel.NONE.insuranceLevel'),
          }}
          status={insuranceLevelStatus}
          {...register('insuranceLevel', {
            required: true,
          })}
          dataCy={''}
          variant='free'
        >
          <div className='flex w-full items-center gap-4 self-stretch'>
            <CoverageLevelIndicator text="Adapté" />
            <div className='flex w-full items-center gap-4 self-stretch'>
              <div className="shrink grow basis-0" dangerouslySetInnerHTML={{
                __html: t('wizard:wizard.steps.coverageLevel.NONE.insuranceLevel'),
              }}
              />
            </div>
          </div>
        </RadioStacked>
      </RadioStackedContainer>
    </InputGroup>
  )
}

export default InsuranceLevel
