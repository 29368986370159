import {
  Nullable,
} from './Wizard'

export enum Coverage {
  MIN = 'MIN',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}

export interface CoverageItem {
  id: Coverage;
  label: string;
  value: Coverage;
}

export const CoverageOptions: CoverageItem[] = [
  {
    value: Coverage.MIN,
    id: Coverage.MIN,
    label: 'Min',
  },
  {
    value: Coverage.LOW,
    id: Coverage.LOW,
    label: 'Faible',
  },
  {
    value: Coverage.MEDIUM,
    id: Coverage.MEDIUM,
    label: 'Moyen',
  },
  {
    value: Coverage.HIGH,
    id: Coverage.HIGH,
    label: 'Fort',
  }
]

export interface Guarantee {
  id: string;
  franchise: number;
  premium: number;
  threshold: number,
  coverage: Coverage;
}
export const DocumentTypes = {
  IPID: 'IPID',
  GENERAL_CONDITIONS: 'GENERAL_CONDITIONS',
  INFORMATION: 'INFORMATION',
  GUARANTEES: 'GUARANTEES',
} as const

export type DocumentType = keyof typeof DocumentTypes;

export interface Document {
  id: string;
  type: DocumentType;
  name: string;
}

export interface Pack {
  id: string;
  premium: number;
  coverages: Coverage[];
}

export interface Offer {
  id: string;
  estimationId: string;
  insurerId: string;
  providerId: Nullable<string>;
  title: string;
  coverage: Record<string, Coverage>;
  externalId: string;
  price: number;
  monthlyPrice: number;
  franchise: number,
  closingType: string;
  guarantees: Guarantee[];
  url: string;
  documents: Document[];
  packs: Pack[];
  rating: number,
  rank: number,
  matchRate: number,
  alternativeMedicine: boolean,
  responsibleContract: boolean
}

export enum MerType {
  SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST',
  IMMEDIATE_CALLBACK = 'IMMEDIATE_CALLBACK',
  CALLBACK_REQUEST = 'CALLBACK_REQUEST',
}

const documentOrder: DocumentType[] = [
  DocumentTypes.GUARANTEES,
  DocumentTypes.GENERAL_CONDITIONS,
  DocumentTypes.IPID
]

export const documentSort = (a: Document, b: Document) => {
  return documentOrder.indexOf(a.type) - documentOrder.indexOf(b.type)
}
