import {
  Text, TextVariant,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'

const MailingLoading: FC = () => {
  return (
    <div className='flex flex-col items-center self-stretch gap-4 text-neutral-400 pt-1'>
      <div className="flex space-x-2">
        <div className="h-3 w-3 bg-primary-400 rounded-full animate-[growShrink_1s_infinite_ease-in-out]" />
        <div className="h-3 w-3 bg-primary-400 rounded-full animate-[growShrink_1s_infinite_ease-in-out] [animation-delay:150ms]" />
        <div className="h-3 w-3 bg-primary-400 rounded-full animate-[growShrink_1s_infinite_ease-in-out] [animation-delay:300ms]" />
      </div>
      <Text variant={TextVariant.REGULAR}>Envoi en cours...</Text>
    </div>
  )
}

export default MailingLoading
