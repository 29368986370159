import {
  Button,
  ButtonSize,
  ButtonType,
  Caption,
  Heading,
  HeadingVariant,
  InteractionMessageAgent,
  TextVariant,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'

import {
  readablePhoneNumber,
} from '@root/util/formatting'
import FrenchFlag from '@assets/icons/french-flag.svg?react'
import Icon from '@components/Icon'

interface CallbackBoxProps {
  phoneNumber: string,
  ariaLabel?: string,
  onClick?: () => void,
}

const CallbackBox: FC<CallbackBoxProps> = ({
  phoneNumber,
  ariaLabel,
  onClick,
}) => {
  return (

    <div className="flex flex-col items-center gap-4 rounded-lg border border-neutral-150 p-4-mobile w-full">
      <div className='flex justify-center items-center gap-4 text-primary-400 self-stretch'>
        <InteractionMessageAgent /><a href="tel:+33 1 86 65 26 10"><Heading variant={HeadingVariant.H3}>{readablePhoneNumber(phoneNumber)}</Heading></a><FrenchFlag />
      </div>
      <Button variant={ButtonType.LIGHT} size={ButtonSize.MEDIUM}
        hasBorderColor={true}
        label='Rappel gratuit'
        iconRight={() => (
          <Icon name='chevron right' size={20} />
        )}
        aria-label={ariaLabel}
        onClick={onClick}
        type='button'
      />
      <div className='text-neutral-400 text-center'>
        <Caption variant={TextVariant.REGULAR}>Ouvert aujourd’hui de 7h à 21h.</Caption>
      </div>
    </div>
  )
}

export default CallbackBox
