import React, {
  useEffect,
  useRef, useState,
} from 'react'
import {
  SubmitHandler, UseFormReturn,
} from 'react-hook-form'
import {
  useDispatch,
} from 'react-redux'
import {
  Button, ButtonSize, ButtonType,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  v4 as uuidv4,
} from 'uuid'
import {
  useNavigate,
} from 'react-router-dom'

import dayjs from '@root/plugins/day'
import BeneficiaryComponent from '@components/steps/Beneficiary'
import InsuranceLevel from '@components/steps/InsuranceLevel'
import InsuranceDate from '@components/steps/InsuranceDate'
import GenderInformation from '@components/steps/GenderInformation'
import BirthDate from '@components/steps/BirthDate'
import ProfessionInformation from '@components/steps/ProfessionInformation'
import SocialRegimeInformation from '@components/steps/SocialRegimeInformation'
import useEstimationWizardForm from '@hooks/useWizardForm'
import {
  OnePageFormData,
  WizardFormData,
} from '@root/domain/Wizard'
import {
  setFormField,
} from '@services/formSlice'
import PostalCode from '@components/steps/PostalCode'
import EmailInformation from '@components/steps/EmailInformation'
import Children from '@components/steps/Children'
import {
  Beneficiary,
} from '@root/domain/Beneficiary'
import ChildrenBirthDates from '@components/steps/ChildrenBirthDates'
import {
  formatTimestamp,
} from '@root/util/date'
import {
  useCreateEstimationMutation,
} from '@services/offers'
import {
  useCreateUserMutation,
} from '@services/user'
import DentalLevel from '@components/steps/DentalLevel'
import HospitalLevel from '@components/steps/HospitalLevel'
import OpticalLevel from '@components/steps/OpticalHealthLevel'
import RegularHealthLevel from '@components/steps/RegularHealthLevel'
import {
  TYPE,
} from '@root/domain/Adult'

const OnePage: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formRef = useRef<HTMLFormElement>(null)
  const [loading, setIsLoading] = useState(false)

  const {
    t,
  } = useTranslation(['wizard', 'validation'])
  const {
    form,
  } = useEstimationWizardForm({
    defaultValues: {
      insuranceDate: dayjs().add(1, 'day').valueOf(),
    },
  })
  const [createEstimation] = useCreateEstimationMutation()
  const [createUser] = useCreateUserMutation()

  const {
    handleSubmit,
    watch,
    setValue,
  } = form

  const beneficiary = watch('beneficiary')
  const insuranceLevel = watch('insuranceLevel')
  const insuranceDate = watch('insuranceDate')

  useEffect(() => {
    if (insuranceDate === null) {
      setValue('insuranceDate', dayjs().add(1, 'day').valueOf())
    }
  }, [setValue, insuranceDate])

  const onSubmit: SubmitHandler<WizardFormData> = async data => {
    dispatch(setFormField(data))
    const userId = uuidv4()
    const estimationId = uuidv4()

    setIsLoading(true)

    try {
      await createUser({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        number: data.phoneNumber.number, // TODO
        country_code: 'FR', // TODO
        uuid: userId,
        birthDate: formatTimestamp(data.adults[0].birthDate),
      }).unwrap()

      const adults = data.adults.map(adult => ({
        birthDate: formatTimestamp(adult.birthDate),
        gender: adult.gender,
        professionId: adult.profession?.id ?? adult.professionRadio,
        socialRegimeId: adult.socialRegime?.id,
        type: adult.type ?? TYPE.SPOUSE,
      }))

      const children = data.children.map(child => ({
        birthDate: formatTimestamp(child.birthDate),
      }))

      await createEstimation({
        consultation: data.regularHealthLevel,
        hospitalization: data.hospitalLevel,
        optic: data.opticalLevel,
        dental: data.dentalLevel,
        address: data.address,
        contractStartDate: data.insuranceDate !== null ? formatTimestamp(data.insuranceDate) : data.insuranceDateRadio,
        userId,
        uuid: estimationId,
        adults,
        children,
        // acceptPartners: data.accept_partners ?? false,
      }).unwrap()

      navigate(`/offre/${estimationId}?preload=true`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <div className="container mx-auto mb-12 flex grow flex-col flex-wrap px-5 pt-6 md:px-0">
        <div className="mb-8">
          <div className="mb-1 text-2xl font-semibold sm:text-[35px]">Découvrez les Meilleures Mutuelles 2025</div>
          <div className="text-lg text-neutral-400">
            Obtenez plusieurs devis en quelques minutes et réduisez votre budget !
          </div>
        </div>
        <form
          className='flex w-full grow flex-col justify-between'
          onSubmit={handleSubmit(onSubmit)}
          ref={formRef}
        >
          <div className="bg-white p-4 mx-[-16px] mb-10 sm:mb-12">
            <div className="text-[20px] font-semibold sm:text-[26px]">Votre contrat</div>
            <div className="my-4 text-neutral-400 sm:text-lg">Qui souhaitez-vous assurer ?</div>
            <BeneficiaryComponent {...form} />
            <div className="mt-8">

              <InsuranceDate {...form} />
            </div>
          </div>

          <div className="bg-white p-4 mx-[-16px] mb-10 sm:mb-12">
            <div className="text-[20px] font-semibold sm:text-[26px]">Vos niveaux de remboursement</div>
            <div className="my-4 text-neutral-400 sm:text-lg">Quels sont vos besoins en termes de remboursement ?</div>
            <InsuranceLevel {...form} />
            {insuranceLevel === '' && (
              <>
                <div className="mb-4 mt-8">
                  Adaptez vos niveaux de remboursement
                </div>
                <div className="flex flex-col items-center gap-4 overflow-y-auto">
                  <RegularHealthLevel {...(form as UseFormReturn<OnePageFormData>)} />
                  <HospitalLevel {...form as UseFormReturn<OnePageFormData>} />
                  <OpticalLevel {...form as UseFormReturn<OnePageFormData>} />
                  <DentalLevel {...form as UseFormReturn<OnePageFormData>} />
                </div>
              </>
            )}
          </div>

          <div className="bg-white p-4 mx-[-16px] mb-10 sm:mb-12">
            <div className="text-[20px] font-semibold sm:text-[26px]">Votre profil</div>
            <div className="my-4 text-neutral-400 sm:text-lg">Civilité</div>
            <div className="flex flex-col gap-8">
              <GenderInformation {...form} />
              <BirthDate {...form} />
              <ProfessionInformation {...form} />
              <SocialRegimeInformation {...form} />
            </div>
          </div>

          {(beneficiary === Beneficiary.PRINCIPAL_AND_SPOUSE ||
              beneficiary === Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN) &&
            (
              <div className="bg-white p-4 mx-[-16px] mb-10 sm:mb-12">
                <div className="text-[20px] font-semibold sm:text-[26px]">Votre conjoint</div>
                <div className="my-4 text-neutral-400 sm:text-lg">Sa civilité</div>
                <div className="flex flex-col gap-8">
                  <GenderInformation id={1} {...form} />
                  <BirthDate id={1} {...form} />
                  <ProfessionInformation id={1} {...form} />
                  <SocialRegimeInformation id={1} {...form} />
                </div>
              </div>
            )}

          {(beneficiary === Beneficiary.PRINCIPAL_AND_CHILDREN ||
            beneficiary === Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN) &&
            (
              <div className="bg-white p-4 mx-[-16px] mb-10 sm:mb-12">
                <div className="text-[20px] font-semibold sm:text-[26px]">Vos enfants</div>
                <div className="my-4 text-neutral-400 sm:text-lg">Nombres d&apos;enfants à assurer</div>
                <div className="flex flex-col gap-8">
                  <Children {...form} />
                  <ChildrenBirthDates {...form} />
                </div>
              </div>
            )}

          <div className="bg-white p-4 mx-[-16px] mb-6">
            <div className="text-[20px] font-semibold sm:text-[26px]">Vos coordonnées</div>
            <div className="mb-8 mt-4">
              <PostalCode {...form} />
            </div>
            <EmailInformation {...form} />
          </div>

          <div className="flex justify-center">
            <div>
              <Button
                type='submit'
                disabled={loading}
                label={t('wizard:wizard.steps.email.nextButton')}
                variant={ButtonType.PRIMARY}
                size={ButtonSize.LARGE}
                pill
                dataCy={'submit'}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default OnePage
