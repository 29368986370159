import {
  FC,
  useEffect,
  useState,
} from 'react'
import {
  Button, ButtonSize,
  ButtonType,
} from '@selectra-it/selectra-ui'
import {
  v4,
} from 'uuid'
import {
  useNavigate,
} from 'react-router-dom'

import ArrowRight from '@assets/icons/arrow-right.svg?react'
import {
  MerStatus,
  useCreateOfferMerMutation,
  useFetchMerQuery,
} from '@root/services/offers'
import LoadingSpinner from '@components/ui/LoadingSpinner'
import {
  InsurerMerOption,
} from '@root/domain/Insurer'
import {
  sendPurchaseEvent,
} from '@root/util/gtm/purchase'
import {
  useAppSelector,
} from '@store/Reducer'
import {
  selectOfferById,
} from '@services/offers/selectors'
import {
  selectInsurerById,
} from '@services/insurers/selectors'

import {
  MerTypeProps,
} from '.'

const Subscription: FC<MerTypeProps> = ({
  type,
  estimationId,
  offerId,
}) => {
  const [id] = useState<string>(v4())
  const navigate = useNavigate()
  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state))
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? ''))
  const [createMer, {
    isLoading,
    isSuccess: isMerCreateSuccess,
  }] = useCreateOfferMerMutation()

  const handleSubscription = async () => {
    createMer({
      offerId,
      type: InsurerMerOption.SUBSCRIPTION,
      id,
      callback_date: null,
    })
  }

  const {
    data,
    refetch,
    isLoading: isLoadingMer,
    isSuccess,
  } = useFetchMerQuery({
    offerId: offerId as string,
    merId: id,
  }, {
    skip: !isMerCreateSuccess,
  })

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>

    if (data?.status !== MerStatus.DONE && data?.status !== MerStatus.ERROR && isMerCreateSuccess) {
      interval = setInterval(() => {
        refetch()
      }, 1200)
    }

    return () => {
      clearInterval(interval)
    }
  }, [refetch, isMerCreateSuccess, data?.status])

  useEffect(() => {
    if (isSuccess && data.status === MerStatus.DONE) {
      sendPurchaseEvent(offer?.monthlyPrice ?? 0, id, {
        id: offerId,
        name: offer?.title,
        brand: insurer?.name,
      })

      navigate(`/offre/${estimationId}/${offerId}/mer/subscription/${id}`)
    }
  }, [isSuccess, data, id, navigate, estimationId, offerId, offer, insurer])

  const buttonType = isSuccess ? ButtonType.SUCCESS : type
  const isDisabled = isLoading || isLoadingMer || (isSuccess && data.status !== MerStatus.DONE)
  const iconRight = isDisabled ? () => <LoadingSpinner size='sm' className='fill-zinc-800' /> : () => <ArrowRight />

  return (
    <div className='flex flex-col gap-6'>
      {type === ButtonType.PRIMARY && (
        <>
          {/* <Heading variant={HeadingVariant.H5}>
            Souscrivez en ligne facilement
          </Heading>
          <Text variant={TextVariant.REGULAR}>
            Redirection vers le site du fournisseur pour une souscription rapide et sécurisée.
          </Text> */}
        </>
      )}
      <Button label='Souscrire en ligne' iconRight={iconRight}
        variant={buttonType} size={ButtonSize.MEDIUM}
        pill
        disabled={isDisabled}
        onClick={handleSubscription}
      />
    </div>
  )
}

export default Subscription
