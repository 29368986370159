import {
  useMemo,
} from 'react'

import {
  selectTaggedOffers,
} from '@root/services/offers/selectors'
import {
  useAppSelector,
} from '@root/store/Reducer'
// Adjust path as needed

export const useTaggedOffers = (estimationId: string) => {
  const taggedOffersSelector = useMemo(() => selectTaggedOffers(estimationId), [estimationId])
  return useAppSelector(taggedOffersSelector)
}
