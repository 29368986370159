import {
  FC,
} from 'react'
import {
  Button,
  ButtonSize,
  ButtonType,
  Caption,
  Header as SelectraHeader,
  TextVariant,
} from '@selectra-it/selectra-ui'
import {
  Link,
  useMatch,
} from 'react-router-dom'
import {
  useDispatch,
} from 'react-redux'
import {
  useTranslation,
} from 'react-i18next'

import Agent from '@assets/agents/picture-1.png'
import Stepper from '@components/wizard/Stepper'
import SelectraLogoMobile from '@assets/selectra-logo-mobile.svg?react'
import SelectraLogoDesktop from '@assets/selectra-logo-desktop.svg?react'
import Icon from '@components/Icon'
import useIsOfferPage from '@hooks/useIsOfferPage'
import {
  HELP_MODAL,
  OFFER_FILTER_MODAL, toggleModal,
} from '@root/services/modalSlice'
import {
  LeadsourceOption, PhoneNumbers,
} from '@root/domain/crm/Record'
import {
  readablePhoneNumber,
} from '@root/util/formatting'
import FrenchFlag from '@assets/icons/french-flag.svg?react'
import useMediaQuery from '@hooks/useMediaQuery'

import HelpModal from './HelpModal'
import CoverageLevelFilterModal from './CoverageLevelFilterModal'

const Header: FC = () => {
  const dispatch = useDispatch()
  const {
    isOfferPage, isOnePagePage, isOfferPageLoaded,
  } = useIsOfferPage()

  const {
    t,
  } = useTranslation('common')

  const isMobile = useMediaQuery('(max-width: 767px)')
  const isFormScreen = useMatch('/recherche')

  const isNotSticky = isMobile && !!isFormScreen

  const phoneNumber = PhoneNumbers[LeadsourceOption.COMPARATOR_HELP_CARD]

  return (
    <SelectraHeader
      sticky={!isNotSticky}
      leftSlot={(
        <Link to={'/recherche'}>
          <SelectraLogoMobile className="block md:hidden" />
          <SelectraLogoDesktop className="hidden md:block" />
        </Link>
      )}
      rightSlot={(
        <>
          <HelpModal />
          {isOfferPage && isOfferPageLoaded && (
            <CoverageLevelFilterModal />
          )}
          <div className='flex items-center gap-4'>
            <div className='text-[#343535] hidden lg:block'>
              <Caption variant={TextVariant.REGULAR} tag='span'>{t('cta_callback_text')}</Caption>
              <Caption variant={TextVariant.BOLD} tag='span'>&nbsp;· {t('free_service')}</Caption>
            </div>
            <div className='hidden lg:block'>
              <Button
                variant={ButtonType.LIGHT}
                size={ButtonSize.SMALL}
                iconLeft={() => (
                  <img className="sui:h-6 sui:w-6 sui:rounded-full" src={Agent} />
                )}
                iconRight={() => <FrenchFlag />}
                onClick={() => {
                  dispatch(toggleModal(HELP_MODAL))
                }}
                label={readablePhoneNumber(phoneNumber)}
                hasBorderColor={true}
              />
            </div>
            {isOfferPage && (
              <div className='block xl:hidden'>
                <Button variant={ButtonType.LIGHT} size={ButtonSize.SMALL}
                  onClick={() => {
                    dispatch(toggleModal(OFFER_FILTER_MODAL))
                  }}
                  label={'Modifier mes niveaux'}
                  iconLeft={() => <Icon name='edit' size={16} />}
                  hasBorderColor={true}
                />

              </div>
            )}
          </div>
        </>
      )}
      centerSlot={!(isOfferPage || isOnePagePage) && (
        <div className='container mx-auto hidden items-start md:flex'>
          <Stepper />
        </div>
      )}
    />
  )
}

export default Header
