import {
  PayloadAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import {
  TYPE,
} from '@root/domain/Adult'
import {
  Beneficiary,
} from '@root/domain/Beneficiary'
import {
  Coverage,
} from '@root/domain/Offer'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  RootState,
} from '@root/store/Reducer'

export const initialState: WizardFormData = {
  amountOfChildren: '',
  children: [],
  beneficiary: Beneficiary.PRINCIPAL,
  insuranceLevel: Coverage.LOW,
  insuranceDate: null,
  insuranceDateRadio: '',
  regularHealthLevel: '',
  opticalLevel: '',
  hospitalLevel: '',
  dentalLevel: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: {
    number: '',
    countryCode: 'FR',
  },
  adults: [{
    birthDate: null,
    profession: null,
    professionRadio: null,
    socialRegime: {
      id: '225f582f-9547-4ce9-8f9d-2e9f2f6d0ce4',
      key: 'general',
      label: 'Général',
    },
    gender: null,
    type: TYPE.PRINCIPAL,
  }
  ],
  address: null,
  terms_and_conditions: false,
}

type SetFormField = Partial<WizardFormData>

const formSlice = createSlice({
  name: 'estimation',
  initialState,
  reducers: {
    setFormField: (state, action: PayloadAction<SetFormField>) => {
      Object.assign(state, action.payload)
    },
    reset: () => {
      return {
        ...initialState,
      }
    },
  },
})

export const getFormState = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.form
)

export const {
  setFormField,
  reset,
} = formSlice.actions

export default formSlice.reducer
