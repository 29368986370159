import {
  Button,
  ButtonSize,
  ButtonType,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'
import {
  Link,
} from 'react-router-dom'
import {
  useDispatch,
} from 'react-redux'

import {
  Offer,
} from '@root/domain/Offer'
import {
  setHasOpenedAnOffer,
} from '@root/services/modalSlice'

interface SubscriptionButtonProps {
  offer: Offer,
  id?: string,
}

const SubscriptionButton: FC<SubscriptionButtonProps> = ({
  offer,
  id,
}) => {
  const dispatch = useDispatch()

  const onClickHandler = () => {
    dispatch(setHasOpenedAnOffer())
  }

  return (
    <Link to={`/offre/${offer.estimationId}/${offer.id}/mer`} onClick={onClickHandler}>
      <Button
        type='submit'
        label='Recevoir un devis'
        variant={ButtonType.PRIMARY}
        size={ButtonSize.MEDIUM}
        pill
        id={id}
      />
    </Link>
  )
}

export default SubscriptionButton
