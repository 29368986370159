import {
  FC,
} from 'react'
import {
  Heading,
  HeadingVariant,
  Text,
  TextVariant,
} from '@selectra-it/selectra-ui'

import {
  LeadsourceOption, PhoneNumbers,
} from '@root/domain/crm/Record'
import Icon from '@components/Icon'

import CallbackBox from '../CallbackModal/CallbackBox'

interface HelpInformationProps {
  setLocation: () => void;
}

const HelpInformation: FC<HelpInformationProps> = ({
  setLocation,
}) => {
  const phoneNumber = PhoneNumbers[LeadsourceOption.COMPARATOR_HELP_CARD]

  return (
    <div className='flex flex-col items-center gap-4 overflow-y-auto text-[#343535]'>
      <div className='flex flex-wrap content-center items-center gap-2 self-stretch fill-[#808284]'>
        <Icon name='help-circle-2' size={24} />
        <Heading variant={HeadingVariant.H5}>Vous avez besoin d’aide ?</Heading>
      </div>
      <div className='flex flex-col gap-6'>
        <div className='text-neutral-400 text-ju'>
          <Text variant={TextVariant.REGULAR} tag='span'> Nos conseillers sont disponibles pour répondre à vos questions par téléphone.</Text>
        </div>

        <CallbackBox phoneNumber={phoneNumber} ariaLabel='Faites-vous rappeler gratuitement'
          onClick={setLocation}
        />
      </div>
    </div>
  )
}

export default HelpInformation
