import {
  FC,
} from 'react'
import {
  Link,
} from 'react-router-dom'
import {
  useTranslation,
} from 'react-i18next'
import {
  FootNote, TextVariant,
} from '@selectra-it/selectra-ui'

import {
  Document,
} from '@root/domain/Offer'
import Icon from '@components/Icon'

interface DocumentListProps {
  document: Document,
}

const DocumentListItem: FC<DocumentListProps> = ({
  document,
}) => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/documents/${document.id}`

  const {
    t,
  } = useTranslation(['offers'])

  const documentName = t(`offers:document:${document.type}` as 'offers:document.IPID')

  return (
    <Link
      className='flex gap-1 items-center text-primary-400 fill-primary-400'
      to={url}
      target='_blank'
    >
      <Icon name='file download' size={24}
        className='fill-success-medium'
      />
      <FootNote variant={TextVariant.UNDERLINED}>{documentName}</FootNote>
    </Link>
  )
}

export default DocumentListItem
