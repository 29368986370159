import {
  Heading,
  HeadingVariant,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'

import {
  useGetEstimationTags,
} from '@hooks/offers/useGetEstimationTags'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import Price from '@components/offers/Price'
import {
  insurerLogos,
} from '@root/domain/Insurer'
import {
  BEST_QUALITY_PRICE,
} from '@root/services/offers'
import {
  documentSort,
} from '@root/domain/Offer'

import Level from '../Level'
import AlternativeMedicine from '../AlternativeMedicine'
import SubscriptionButton from '../Subscription/SubscriptionButton'
import DocumentList from '../Documents/Horizontal/DocumentList'
import Tag from '../Tag/Horizontal/Tag'
import {
  OfferItemProps,
} from '..'

const FeaturedOffer: FC<OfferItemProps> = ({
  offer,
}) => {
  const {
    matchRate, monthlyPrice, title, insurerId, coverage,
  } = offer

  const {
    tag,
  } = useGetEstimationTags(offer.estimationId, offer.id)
  const insurer = useAppSelector(state => selectInsurerById(state, insurerId))

  if (!insurer) {
    return null
  }

  const Logo = insurerLogos[insurer.id]

  const featuredTagClasses = tag && tag === BEST_QUALITY_PRICE ? 'border-primary-400 border-2' : 'boder-neutral-100 border'

  return (
    <div className={`${featuredTagClasses} flex flex-col border-neutral-100 bg-white rounded-3xl border h-full`}>
      <div className='flex-auto flex self-stretch items-start flex-col px-4 pt-4 pb-6 gap-6'>
        {tag && <Tag tag={tag} /> }
        <div className='flex flex-auto flex-col w-full gap-4'>
          <div className='flex flex-auto flex-col gap-2'>
            <div className='flex gap-2 w-full'>
              <div className='flex flex-col grow items-start gap-4 text-neutral-500'>
                {typeof Logo === 'string'
                  ? <img src={Logo} className='h-8'/>
                  : <Logo height={32} /> }
                <Heading variant={HeadingVariant.H6} >{title}</Heading>
              </div>
              <Price price={monthlyPrice} withDecimals={false} />
            </div>
            <p className='text-sm text-neutral-350 md:text-base'>
              Répond à <strong>{matchRate} %</strong> de vos besoins
            </p>
          </div>
          <div>
            <ul className='mb-2 flex flex-col gap-2'>
              {['consultation', 'hospitalization', 'dental', 'optical'].map(key => {
                return coverage[key] && (
                  <Level
                    key={key}
                    level={coverage[key]}
                    label={key}
                    isIncluded={false}
                  />
                )
              })}
              <AlternativeMedicine alternativeMedicine={offer.alternativeMedicine} />
            </ul>
          </div>
        </div>
        <div className='w-full'>
          <SubscriptionButton offer={offer} id={tag ?? undefined} />
        </div>
      </div>
      <DocumentList documents={offer.documents.toSorted(documentSort)} />
    </div>
  )
}

export default FeaturedOffer
