import {
  useEffect,
} from 'react'
import {
  animated, useSpring,
} from '@react-spring/web'
import {
  useParams,
} from 'react-router-dom'

import {
  useFetchUserQuery,
} from '@root/services/user'
import {
  EstimationStatus,
} from '@root/domain/Estimation'
import CallbackBanner from '@components/offers/CallbackBanner'
import {
  useGetInsurersQuery,
} from '@root/services/insurers'
import {
  settings,
} from '@root/settings'
import useGetOffersByEstimationId from '@hooks/offers/useGetOffersByEstimationId'
import EditEstimation from '@components/offers/EditEstimation'
import useMediaQuery from '@hooks/useMediaQuery'
import {
  useTaggedOffers,
} from '@hooks/offers/useTaggedOffers'
import MoreOffers from '@components/offers/MoreOffers'

import {
  addMatchRateBannerWarning,
} from './OfferListDecorator'
import OfferItemHorizontal from './OfferItem/OfferItemHorizontal'
import OfferItem from './OfferItem/OfferItem'
import FeaturedOffer from './OfferItem/Variants/FeaturedOffer'

const Offers = () => {
  const params = useParams()
  const estimationId = params.estimationId ?? ''

  const styles = getComputedStyle(document.documentElement)
  const breakpoint = styles.getPropertyValue('--breakpoint-md')
  const isTabletOrGreater = useMediaQuery(`(min-width: ${breakpoint})`)

  useGetInsurersQuery()

  const {
    data,
    refetch,
    error,
    isSuccess,
  } = useGetOffersByEstimationId(estimationId)

  const fetchUser = useFetchUserQuery(data?.estimation?.userId ?? '', {
    skip: !isSuccess,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (data?.estimation?.status === EstimationStatus.COMPLETE || error) {
        clearInterval(interval)
        return
      }

      refetch()
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [data, refetch, error])

  const [offerListStyle] = useSpring({
    from: {
      transform: 10,
      opacity: 0,
    },
    to: {
      transform: fetchUser.isSuccess && isSuccess ? 0 : 10,
      opacity: fetchUser.isSuccess && isSuccess ? 1 : 0,
    },
    leave: [
      {
        transform: 'none',
      }
    ],
  }, [fetchUser, isSuccess])

  const items = addMatchRateBannerWarning(data?.items, settings.offerSortStategy, isTabletOrGreater ? OfferItemHorizontal : OfferItem)

  const taggedOffers = useTaggedOffers(estimationId)

  const withBanner = items?.length
    ? [...items.slice(0, 1),
      <MoreOffers key='more-banner' />,
      ...items.slice(1, 6),
      <CallbackBanner
        key='callback-banner'
        title='Vous n’êtes pas sûr(e) ?'
      />, ...items.slice(6, items.length)]
    : [
      <CallbackBanner key='callback-banner'
        title='Besoin d’aide ?'
      />]

  return (
    <div className='flex h-full flex-col gap-8 md:gap-12'>
      <animated.div className='flex w-full flex-col md:gap-6 divide-y divide-neutral-100' role="list"
        style={{
          ...offerListStyle,
          transform: offerListStyle.transform.to(t => {
            return (t ? `translateY(${t}%)` : 'none')
          }),
        }}
      >
        <div className='md:hidden flex w-full flex-col gap-4'>
          {taggedOffers.map(offer => (
            <FeaturedOffer key={offer.id} offer={offer} />
          ))}

          {data?.estimation && <EditEstimation estimation={data?.estimation} />}
        </div>
        {...withBanner}
      </animated.div>
    </div>
  )
}

export default Offers
