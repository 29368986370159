import {
  http,
} from 'msw'

import user from '@fixtures/user.json'
import offers from '@fixtures/offers.json'
import socialRegime from '@fixtures/socialRegime.json'
import profession from '@fixtures/profession.json'
import level from '@fixtures/level.json'
import insurers from '@fixtures/insurers.json'

const handlers = [
  http.get('*/api/health/professions', () => {
    return Response.json(profession, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }),
  http.get('*/api/health/social-regimes', () => {
    return Response.json(socialRegime, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }),
  http.get('*/api/health/coverage-levels', () => {
    return Response.json(level, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }),
  http.get('*/api/health/estimation/*', () => {
    return Response.json(offers, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }),
  http.get('*/api/user/*', () => {
    return Response.json(user, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }),
  http.get('*/api/insurers', () => {
    return Response.json(insurers, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  })
]

export {
  handlers
}
