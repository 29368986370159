import React from 'react'
import {
  createRoot,
} from 'react-dom/client'
import {
  Provider,
} from 'react-redux'
import {
  PersistGate,
} from 'redux-persist/integration/react'
import ReactModal from 'react-modal'
import {
  RouterProvider,
} from 'react-router-dom'
import {
  setupWorker,
} from 'msw/browser'

import {
  createStore,
} from '@store/Store'
import {
  handlers,
} from '@test/server/serverHandlers'

import './index.css'
import './plugins/sentry'
import './plugins/gtm'
import './plugins/datadog'
import './plugins/i18n'
import {
  router,
} from './routes/routes'

const container = document.getElementById('root')
const root = createRoot(container)

ReactModal.setAppElement(container)

const {
  store, persistor,
} = createStore()

export const application = (
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading='loading persistor' persistor={persistor}>
        <RouterProvider router={router}
          future={{
            v7_startTransition: true,
          }}
        />
      </PersistGate>
    </Provider>
  </React.StrictMode>
)

if (window.Cypress) {
  window.store = store
}

window.store = store

const worker = setupWorker(
  // Provide request handlers
  ...handlers
)

async function prepare () {
  if (import.meta.env.DEV && import.meta.env.VITE_MSW_ENABLED === 'true') {
    return worker.start({
      onUnhandledRequest: 'bypass',
    })
  }
}

prepare().then(() => {
  root.render(
    application
  )
})
