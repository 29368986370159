import {
  InputGroup, getErrorMessages, ErrorMessages,
  DateInput,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  Controller,
  UseFormReturn,
} from 'react-hook-form'

import useGetInputStatus from '@hooks/useGetInputStatus'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  isReasonableDate,
  isTodayOrFutureDate,
} from '@root/util/date'

const InsuranceDate = ({
  control, formState,
}: UseFormReturn<WizardFormData>) => {
  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const {
    errors,
  } = formState

  const status = useGetInputStatus(errors?.insuranceDate?.type)

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.insuranceDate.required'),
    reasonableDate: t('validation:validation.form.insuranceDate.reasonableDate'),
  }, errors?.insuranceDate?.type)

  return (
    <InputGroup
      errors={inputErrors}
      description={t('wizard:wizard.steps.insuranceDate.description')}
    >
      <Controller
        name='insuranceDate'
        control={control}
        rules={{
          validate: {
            required: () => true,
            reasonableDate: value => {
              if (!value) {
                return false
              }

              return !!(isReasonableDate(value) && isTodayOrFutureDate(value))
            },
          },
        }}
        render={renderParams => {
          const {
            field: {
              value,
              ref,
              name,
              onChange,
              onBlur,
            },
          } = renderParams

          return (
            <DateInput
              label="Date de début du contrat (provisoire)"
              value={value ? new Date(value) : null}
              name={name}
              status={status}
              onBlur={onBlur}
              onChange={date => {
                onChange(date?.getTime())
              }}
              ref={ref}
            />
          )
        }}
      />
    </InputGroup>
  )
}

export default InsuranceDate
