import {
  FC,
} from 'react'
import {
  Outlet,
} from 'react-router-dom'
import {
  Default,
  useScrollToTop,
} from '@selectra-it/selectra-ui'

import Header from '@components/ui/Header'
import useIsOfferPage from '@hooks/useIsOfferPage'
import ProgressBar from '@components/wizard/ProgressBar'
import MobileStickyFooter from '@components/ui/MobileStickyFooter'
import SixtySecondCallbackModal from '@components/ui/SixtySecondCallbackModal'
// import Debugger from '@components/ui/Debugger'

const App: FC = () => {
  useScrollToTop('offre')

  const {
    isOfferPage,
    isOnePagePage,
  } = useIsOfferPage()

  return (
    <>
      <Default header={(
        <>
          <Header />
          {!(isOfferPage || isOnePagePage) && <ProgressBar />}
        </>
      )}
      >
        <Outlet />

        {/* <Debugger /> */}

      </Default>
      {isOfferPage && <SixtySecondCallbackModal />}
      <MobileStickyFooter />
    </>
  )
}

export default App
