import {
  FC,
} from 'react'

import {
  Document,
} from '@root/domain/Offer'

import DocumentListItem from './DocumentListItem'

interface DocumentListProps {
  documents: Document[],
}

const DocumentList: FC<DocumentListProps> = ({
  documents,
}) => {
  return (
    <div className='rounded-b-3xl bg-neutral-50 px-6 py-4 flex flex-wrap gap-6'>
      {documents.map(document => {
        return (
          <DocumentListItem key={document.id} document={document}/>
        )
      })}
    </div>
  )
}

export default DocumentList
