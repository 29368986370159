export const readablePhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')
}

export const priceInCentsToString = (price: number): string => {
  const euros = Math.floor(price / 100)
  const cents = (price % 100).toString().padStart(2, '0')

  return `${euros},${cents}`
}
