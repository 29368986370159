import {
  FC,
} from 'react'
import {
  Caption, TextVariant,
} from '@selectra-it/selectra-ui'

import {
  InsurerMerOption,
} from '@root/domain/Insurer'
import {
  Nullable,
} from '@root/domain/Wizard'

interface MerActionMessageProps {
  primary: InsurerMerOption
  secondary: Nullable<InsurerMerOption>
}

const fromPrimary = (primary: InsurerMerOption): string => {
  switch (primary) {
    case InsurerMerOption.SUBSCRIPTION:
      return 'Souhaitez-vous être redirigé vers le site du fournisseur pour une souscription rapide et sécurisée ?'
    case InsurerMerOption.CALLBACK_REQUEST:
      return 'Souhaitez-vous être rappelé par un conseiller pour analyser votre devis et poser vos questions ?'
    case InsurerMerOption.CALLBACK_REQUEST_SELECTRA:
      return 'Souhaitez-vous être rappelé par un expert en assurance pour analyser votre devis et poser vos questions ?'
  }
}

const fromPrimaryAndSecondary = (primary: InsurerMerOption, secondary: InsurerMerOption): string => {
  switch (primary) {
    case InsurerMerOption.SUBSCRIPTION:
      switch (secondary) {
        case InsurerMerOption.CALLBACK_REQUEST:
          return 'Souhaitez-vous être rappelé par l’assureur ou souscrire en ligne ?'
        case InsurerMerOption.CALLBACK_REQUEST_SELECTRA:
          return 'Souhaitez-vous appeler un expert ou souscrire en ligne ?'
      }
      break
    case InsurerMerOption.CALLBACK_REQUEST:
      switch (secondary) {
        case InsurerMerOption.SUBSCRIPTION:
          return 'Souhaitez-vous être rappelé par l’assureur ou souscrire en ligne ?'
        case InsurerMerOption.CALLBACK_REQUEST_SELECTRA:
          return 'Vous avez demandé un rappel et demandé un rappel'
      }
      break
    case InsurerMerOption.CALLBACK_REQUEST_SELECTRA:
      switch (secondary) {
        case InsurerMerOption.SUBSCRIPTION:
          return 'Souhaitez-vous appeler un expert ou souscrire en ligne ?'
        case InsurerMerOption.CALLBACK_REQUEST:
          return 'Vous avez demandé un rappel et demandé un rappel'
      }
      break
  }

  return ''
}

const MerActionMessage: FC<MerActionMessageProps> = ({
  primary,
  secondary,
}) => {
  const message = secondary ? fromPrimaryAndSecondary(primary, secondary) : fromPrimary(primary)

  return (
    <div className='text-[#242525]'>
      <Caption variant={TextVariant.BOLD}>
        {message}
      </Caption>
    </div>
  )
}

export default MerActionMessage
