import {
  BEST_PRICE, BEST_QUALITY, BEST_QUALITY_PRICE, BEST_RATING, FREE_TRIAL_1_MONTH,
} from '@root/services/offers'

export default {
  offers: {
    message1: 'Voici la <strong> liste des offres </strong> les plus adaptées à votre profil 👍',
    message2: '{{insurerCount}} partenaires interrogés. Résultats non exhaustifs.',
  },
  noOffers: {
    message1: 'Je ne suis pas parvenu à trouver une mutuelle qui corresponde à votre profil.',
    message2: 'Contactez gratuitement un de nos courtiers en assurance au ',
    message3: ' afin de trouver une offre adaptée 🤓',
  },
  coverage: {
    MIN: 'Minimum',
    LOW: 'Basique',
    MEDIUM: 'Moyenne',
    HIGH: 'Complète',
  },
  document: {
    IPID: 'Fiche produit',
    INFORMATION: 'Tableau de garanties',
    GENERAL_CONDITIONS: 'Conditions générales',
    GUARANTEES: 'Tableau de garanties',
  },
  edit: {
    PRINCIPAL: 'Vous',
    SPOUSE: 'conjoint',
  },
  tags: {
    [BEST_PRICE]: 'Le moins cher',
    [BEST_QUALITY]: 'Qualité',
    [BEST_QUALITY_PRICE]: 'Qualité-prix',
    [BEST_RATING]: 'Meilleure couverture',
    [FREE_TRIAL_1_MONTH]: '1 mois offert !',
  },
  customer_service: {
    online_chat: 'Chat en ligne',
    phone: 'Téléphone',
  },
  application_fee_zero: 'Aucun',
  application_fee_other: '{{count}}€ (inclus dans le prix affiché)',
  coverage_levels: {
    consultation: 'Soins courants',
    hospitalization: 'Hospitalisation',
    optical: 'Optique',
    dental: 'Dentaire',
  },
  alternativeMedicine: 'Médecine douce',
  responsibleContract: 'Contrat responsable',
  administrationFee: 'Frais de dossier',
  customerService: 'Service client',
  subscription: {
    success: {
      callback: 'Vos coordonnées ont été transmises avec succès à {{insurerName}} !',
      callback_selectra: 'Nous avons enregistré votre demande',
      description: 'Vous serez rappelé dans les plus brefs délais au {{phoneNumber}}',
    },
  },
  mer: {
    request_callback: {
      title: 'L\'assureur vous rappelle',
      description: 'Laissez vos coordonnées et l\'assureur vous rappellera pour finaliser votre souscription.',
    },
    request_callback_selectra: {
      menu_title: 'Souscrire à cette offre avec un expert Selectra',
      title: 'Rappel gratuit d\'un expert',
      description: 'Laissez vos coordonnées et nous vous rappellerons pour finaliser votre souscription.',
    },
  },
  coverageLevelModal: {
    regularHealth: {
      MIN: 'Consultations rares chez généraliste/spécialiste.',
      LOW: 'Consultations aux tarifs conventionnels, sans dépassements d\'honoraires.',
      MEDIUM: 'Consultations avec dépassements d\'honoraires modérés.',
      HIGH: 'Consultations avec dépassements d\'honoraires élevés.',
    },
    dental: {
      MIN: 'Dentition saine, contrôles réguliers (détartrage, petites caries).',
      LOW: 'Soins et prothèses 100% santé, sans reste à charge.',
      MEDIUM: 'Orthodontie, prothèses, inlays, onlays avec couverture moyenne.',
      HIGH: 'Orthodontie, prothèses, inlays, onlays avec couverture élevée.',
    },
    hospital: {
      MIN: 'Pas de prise en charge des dépassements ni de la chambre. Soins remboursés à 100% de la base Sécu.',
      LOW: 'Honoraires couverts jusqu\'à 150%, faible prise en charge de la chambre particulière.',
      MEDIUM: 'Honoraires couverts jusqu\'à 200%, prise en charge moyenne de la chambre particulière.',
      HIGH: 'Honoraires couverts à plus de 200%, chambre particulière bien prise en charge.',
    },
    optical: {
      MIN: 'Vous ne portez pas de lunettes.',
      LOW: 'Lunettes à verres simples ou complexes, prise en charge <200€/an.',
      MEDIUM: 'Lunettes à verres simples ou complexes, prise en charge entre 200€ et 300€/an + lentilles.',
      HIGH: 'Lunettes à verres simples ou complexes, prise en charge >300€/an + lentilles + chirurgie réfractive.',
    },
    alternativeMedicine: {
      true: 'Forfait annuel pour consultation nutritionniste, diétiticien, acupuncteur, podologue, homéopathe etc...',
      '': 'Médecine douce : l’homéopathie, la mésothérapie, l’acupuncture, l’ostéopathie...',
    },
  },
}
