import {
  InputGroup, getErrorMessages, ErrorMessages, DateInput,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  Controller,
  UseFormReturn,
} from 'react-hook-form'

import useGetInputStatus from '@hooks/useGetInputStatus'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import {
  isPastDate, isReasonableDate,
} from '@root/util/date'

const ChildrenBirthDates = (form: UseFormReturn<WizardFormData>) => {
  const children = form.watch('amountOfChildren')

  return (
    <div className='flex flex-col gap-6'>
      {Array.from({
        // @ts-ignore
        length: children,
      }).map((_, index) => (
        <ChildrenBirthDate key={index} id={index}
          form={form}
        />
      ))}
    </div>
  )
}

interface Props {
  id: number;
  form: UseFormReturn<WizardFormData>;
}

const ChildrenBirthDate = ({
  id, form,
}: Props) => {
  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const {
    control, formState: {
      errors,
    },
  } = form

  const errorType = errors.children?.[id]?.birthDate?.type

  const status = useGetInputStatus(errorType)

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addChildrenBirthDate'),
  }, errorType)

  return (
    <InputGroup description={t('wizard:wizard.steps.birthDate.description')}
      errors={inputErrors}
    >
      <Controller
        name={`children.${id}.birthDate` as 'children.0.birthDate'}
        control={control}
        rules={{
          required: true,
          validate: {
            reasonableDate: value => {
              if (!value) {
                return true
              }

              return !!(isReasonableDate(value) && isPastDate(value))
            },
          },
        }}
        render={renderParams => {
          const {
            field: {
              value,
              ref,
              name,
              onChange,
              onBlur,
            },
          } = renderParams

          return (
            <DateInput label={t('wizard:wizard.steps.birthDate.label')}
              value={value ? new Date(value) : null}
              name={name}
              status={status}
              onBlur={onBlur}
              onChange={date => {
                onChange(date?.getTime())
              }}
              ref={ref}
            />
          )
        }}
      />

    </InputGroup>
  )
}

export default ChildrenBirthDates
