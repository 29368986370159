import {
  FC,
} from 'react'

import {
  Coverage,
} from '@root/domain/Offer'

interface CoverageLevelVerticalBarsProps {
  level: Coverage
}

const GreenBarMap: Record<Coverage, number> = {
  MIN: 1,
  LOW: 2,
  MEDIUM: 3,
  HIGH: 4,
}

const CoverageLevelVerticalBars: FC<CoverageLevelVerticalBarsProps> = ({
  level,
}) => {
  const greenBarLength = GreenBarMap[level]
  const grayBarLength = 4 - greenBarLength

  const greenBar = Array.from({
    length: greenBarLength,
  }).map((_, index) => (
    <div
      key={index}
      className="h-5 w-[7px] rounded-xs bg-success-light"
    />
  ))

  const grayBar = Array.from({
    length: grayBarLength,
  }).map((_, index) => (
    <div
      key={index}
      className='h-5 w-[7px] rounded-xs bg-neutral-100'
    />
  ))

  return (
    <div className='inline-flex items-center justify-center gap-[3px]'>
      {greenBar}
      {grayBar}
    </div>
  )
}

export default CoverageLevelVerticalBars
