import {
  useEffect,
} from 'react'
import {
  useNavigate,
} from 'react-router-dom'

const AB_NAME = 'one-form'

const AB = () => {
  const navigate = useNavigate()
  let version = localStorage.getItem(`ab_${AB_NAME}`)

  useEffect(() => {
    if (!version) {
      const random = Math.random() * 100

      // eslint-disable-next-line react-hooks/exhaustive-deps
      version = random > 50 ? 'A' : 'B'

      localStorage.setItem(`ab_${AB_NAME}`, version)
    }

    navigate(version === 'A' ? '/recherche' : '/recherche')
  })

  return null
}

export default AB
