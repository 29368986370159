import {
  FC,
} from 'react'
import {
  Modal,
} from '@selectra-it/selectra-ui'
import {
  Outlet,
  useLocation, useNavigate, useParams,
} from 'react-router-dom'

import MerHeader from './MerHeader'

const SubscriptionModal: FC = () => {
  const {
    estimationId,
  } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const open = location.pathname.includes('mer')
  const isWelcomeScreen = location.pathname.endsWith('mer')

  return (
    <Modal open={open}
      setOpen={() => {
        navigate(`/offre/${estimationId}`)
      }}
      header={isWelcomeScreen ? <MerHeader /> : undefined}
    >
      <div className='flex h-full flex-col overflow-y-auto'>
        <Outlet />
      </div>
    </Modal>
  )
}

export default SubscriptionModal
