import {
  InputGroup, getErrorMessages, ErrorMessages, GoogleAutocomplete,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  Controller, UseFormReturn,
} from 'react-hook-form'

import {
  WizardFormData,
} from '@root/domain/Wizard'

const PostalCode = ({
  control, formState,
}: UseFormReturn<WizardFormData>) => {
  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const {
    errors,
  } = formState

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addPostalCode'),
  }, errors?.address?.type)

  return (
    <InputGroup
      description={t('wizard:wizard.steps.postalCode.description')}
      errors={inputErrors}
    >
      <Controller name='address' control={control}
        rules={{
          required: true,
        }} render={renderParams => {
          const {
            field: {
              value,
              ref,
              name,
              onChange,
              onBlur,
            },
          } = renderParams

          return (
            <GoogleAutocomplete
              id='address'
              value={value}
              apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
              componentRestrictions={{
                country: 'FR',
              }}
              label={t('wizard:wizard.steps.postalCode.label')}
              types={[
                'postal_code'
              ]}
              ref={ref}
              name={name}
              onChange={value => {
                onChange(value)
                onBlur()
              }}
              type={'tel'}
              displayValue={value => value?.structured_formatting.main_text ?? ''}
            />
          )
        }}
      />
    </InputGroup>
  )
}

export default PostalCode
