import {
  FC, useState,
} from 'react'
import {
  Control, Controller, LiteralUnion, RegisterOptions, UseFormSetValue,
} from 'react-hook-form'
import {
  Combobox,
} from '@selectra-it/selectra-ui'

import {
  SocialRegime, useGetSocialRegimeQuery,
} from '@root/services/socialRegime'
import {
  WizardFormData,
} from '@root/domain/Wizard'
import useGetInputStatus from '@hooks/useGetInputStatus'

interface SocialRegimeProps {
  control: Control<WizardFormData>
  error: LiteralUnion<keyof RegisterOptions, string> | undefined;
  setValue: UseFormSetValue<WizardFormData>
  label: string
  id: number
}

const SocialRegimeComponent: FC<SocialRegimeProps> = ({
  control,
  error,
  label,
  id,
}) => {
  const {
    data: items,
  } = useGetSocialRegimeQuery()

  const [query, setQuery] = useState<string>('')
  const status = useGetInputStatus(error)

  if (!items) return null

  const displayValue = (value: SocialRegime | null): string => value?.label ?? ''

  const filteredItems =
      query === ''
        ? items
        : (items.filter(item =>
          item.label.toLowerCase().includes(query.toLowerCase())
        ))

  return (
    <Controller
      name={`adults.${id}.socialRegime`}
      control={control}
      rules={{
        required: true,
      }}
      render={renderParams => {
        const {
          field: {
            value,
            ref,
            name,
            onChange,
          },
        } = renderParams

        return (
          <Combobox<SocialRegime>
            id={`social-regime-${id}`}
            ref={ref}
            displayValue={displayValue}
            item={value}
            setQuery={setQuery}
            setItem={onChange}
            items={filteredItems}
            label={label}
            status={status}
            name={name}
            readOnly={true}
            canClearValue={false}
            dataCy="social-regime"
            immediate={true}
            showExpandOptionsDropdown={true}
          />
        )
      }}
    />
  )
}

export default SocialRegimeComponent
