import {
  FC, SVGProps,
} from 'react'

import Icon from '@components/Icon'

export enum Beneficiary {
  PRINCIPAL = 'PRINCIPAL',
  PRINCIPAL_AND_CHILDREN = 'PRINCIPAL_AND_CHILDREN',
  PRINCIPAL_SPOUSE_AND_CHILDREN = 'PRINCIPAL_SPOUSE_AND_CHILDREN',
  PRINCIPAL_AND_SPOUSE = 'PRINCIPAL_AND_SPOUSE',
}

export const beneficiaryHasChildren: Record<Beneficiary, boolean> = {
  [Beneficiary.PRINCIPAL]: false,
  [Beneficiary.PRINCIPAL_AND_CHILDREN]: true,
  [Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN]: true,
  [Beneficiary.PRINCIPAL_AND_SPOUSE]: false,
}

export const numberOfAdults: Record<Beneficiary, number> = {
  [Beneficiary.PRINCIPAL]: 1,
  [Beneficiary.PRINCIPAL_AND_CHILDREN]: 1,
  [Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN]: 2,
  [Beneficiary.PRINCIPAL_AND_SPOUSE]: 2,
}

export const BeneficiaryIcons: Record<Beneficiary, FC<SVGProps<SVGSVGElement>>> = {
  [Beneficiary.PRINCIPAL]: () => <Icon name='beneficiary_principal' size={48} />,
  [Beneficiary.PRINCIPAL_AND_CHILDREN]: () => <Icon name='beneficiary_principal_and_children' size={48} />,
  [Beneficiary.PRINCIPAL_SPOUSE_AND_CHILDREN]: () => <Icon name='beneficiary_couple_and_children' size={48} />,
  [Beneficiary.PRINCIPAL_AND_SPOUSE]: () => <Icon name='beneficiary_couple' size={48} />,
}
