import {
  PayloadAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import {
  Offer,
} from '@root/domain/Offer'
import {
  Nullable,
} from '@root/domain/Wizard'
import {
  RootState,
} from '@root/store/Reducer'

export const SUBSCRIPTION_MODAL = 'subscription'
export const OFFER_FILTER_MODAL = 'offerFilter'
export const HELP_MODAL = 'help'
export const SIXTY_SECOND_MODAL = 'sixtySecond'

type MODALS = typeof SUBSCRIPTION_MODAL | typeof OFFER_FILTER_MODAL | typeof HELP_MODAL | typeof SIXTY_SECOND_MODAL
interface ModalState {
  [SUBSCRIPTION_MODAL]: boolean,
  [OFFER_FILTER_MODAL]: boolean,
  [HELP_MODAL]: boolean,
  [SIXTY_SECOND_MODAL]: boolean,
  tracking: {
    hasOpenedAnOffer: boolean,
    timestamp: Nullable<number>,
    hasOpenedModal: boolean,
  },
  offer: Nullable<Offer>
}

const initialState: ModalState = {
  [SUBSCRIPTION_MODAL]: false,
  [OFFER_FILTER_MODAL]: false,
  [HELP_MODAL]: false,
  [SIXTY_SECOND_MODAL]: false,
  tracking: {
    hasOpenedAnOffer: false,
    timestamp: null,
    hasOpenedModal: false,
  },
  offer: null,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleSubscriptionModal: state => {
      state.subscription = !state.subscription
    },
    openSubscriptionModal: (state, action: PayloadAction<Offer>) => {
      state.subscription = true
      state.offer = action.payload
    },
    toggleModal: (state, action: PayloadAction<MODALS>) => {
      state[action.payload] = !state[action.payload]
    },
    setTrackingTimestamp: (state, action: PayloadAction<number>) => {
      if (!state.tracking) {
        state.tracking = {
          hasOpenedAnOffer: false, timestamp: null, hasOpenedModal: false,
        }
      } // Ensure tracking exists
      state.tracking.timestamp = action.payload
    },
    setHasOpenedAnOffer: state => {
      if (!state.tracking) {
        state.tracking = {
          hasOpenedAnOffer: false, timestamp: null, hasOpenedModal: false,
        }
      } // Ensure tracking exists
      state.tracking.hasOpenedAnOffer = true
    },
    resetTracking: state => {
      state.tracking = {
        hasOpenedAnOffer: false,
        timestamp: null,
        hasOpenedModal: true,
      }
    },
  },
})

export const isModalOpen = createSelector(
  (state: RootState) => state,
  (state: RootState, modal: MODALS) => modal,
  (state: RootState, modal: MODALS) => state.modal[modal]
)

export const getOffer = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.modal.offer
)

export const {
  toggleSubscriptionModal,
  openSubscriptionModal,
  toggleModal,
  setTrackingTimestamp,
  setHasOpenedAnOffer,
  resetTracking,
} = modalSlice.actions

export default modalSlice.reducer
