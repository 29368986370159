import {
  useEffect,
} from 'react'
import {
  useDispatch,
} from 'react-redux'

import {
  setTrackingTimestamp, SIXTY_SECOND_MODAL, toggleModal,
} from '@root/services/modalSlice'
import {
  RootState, useAppSelector,
} from '@root/store/Reducer'

const useOpenSixtySecondModal = () => {
  const dispatch = useDispatch()
  const {
    tracking,
  } = useAppSelector(
    (state: RootState) => {
      return state.modal
    }
  )

  const MILISECONDS = 60 * 1000

  useEffect(() => {
    // If no timestamp, set it now (first page load)
    if (!tracking || !tracking.timestamp) {
      dispatch(setTrackingTimestamp(new Date().getTime()))
      return
    }

    // If user has already interacted, don't show the modal
    if (tracking.hasOpenedAnOffer || tracking.hasOpenedModal) return

    // Calculate remaining time
    const timeElapsed = new Date().getTime() - tracking.timestamp
    const remainingTime = Math.max(MILISECONDS - timeElapsed, 0)

    const timer = setTimeout(() => {
      dispatch(toggleModal(SIXTY_SECOND_MODAL))
    }, remainingTime)

    return () => clearTimeout(timer)
  }, [dispatch, tracking, MILISECONDS])
}

export default useOpenSixtySecondModal
