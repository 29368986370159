import {
  InputGroup,
  getErrorMessages,
  ErrorMessages,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  UseFormReturn,
} from 'react-hook-form'

import SocialRegime from '@components/form/SocialRegime'
import {
  WizardFormData,
} from '@root/domain/Wizard'

interface Props extends UseFormReturn<WizardFormData> {
  id?: number;
}

const SocialRegimeInformation = ({
  id = 0, control, setValue, formState,
}: Props) => {
  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const {
    errors,
  } = formState

  const errorType = errors.adults?.[id]?.socialRegime?.type

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.mustSelectSocialRegime'),
  }, errorType)

  return (
    <div className='flex flex-col gap-6'>
      <InputGroup
        description={t('wizard:wizard.steps.socialRegime.description')}
        errors={inputErrors}
      >
        <SocialRegime
          control={control}
          error={errorType}
          setValue={setValue}
          label={t('wizard:wizard.steps.socialRegime.label')}
          id={id}
        />
      </InputGroup>
    </div>
  )
}

export default SocialRegimeInformation
