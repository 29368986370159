export default {
  included: 'Incluse',
  not_included: 'Non incluse',
  yes: 'Oui',
  no: 'Non',
  visitProviderSite_zero: "Aller sur le site d'{{insurer}}",
  visitProviderSite_one: 'Aller sur le site de {{insurer}}',
  month: 'mois',
  cta_callback_text: 'Nos Experts à votre écoute',
  free_service: 'Service gratuit',
}
