import {
  FC,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'

import {
  Coverage,
} from '@root/domain/Offer'

import CoverageLevelVerticalBars from './CoverageLevel/CoverageLevelVerticalBars'

interface CoverageLevelIndicatorProps {
  level?: Coverage
  text?: string
}

const CoverageLevelIndicator: FC<CoverageLevelIndicatorProps> = ({
  level,
  text,
}) => {
  const {
    t,
  } = useTranslation(['wizard'])

  return (
    <div className='inline-flex h-[52px] shrink-0 basis-[86px] flex-col items-center justify-center gap-2 border-r border-[#a9abae] pl-2 pr-4-mobile'>
      {level && (
        <CoverageLevelVerticalBars level={level} />
      )}

      <span className='text-sm font-semibold leading-160 text-neutral-400'>{
        text ?? t(`wizard.steps.coverageLevel.${level ?? 'NONE'}.label` as 'wizard.steps.coverageLevel.HIGH.label')
      }</span>
    </div>
  )
}

export default CoverageLevelIndicator
