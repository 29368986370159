import {
  ButtonGroup, ButtonGroupVariant, Caption, TextVariant,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  Controller, UseFormReturn,
} from 'react-hook-form'

import {
  CoverageOptions as options,
} from '@root/domain/Offer'
import {
  OnePageFormData,
} from '@root/domain/Wizard'
import Icon from '@components/Icon'
import useGetButtonGroupStatus from '@hooks/useGetButtonGroupStatus'
import CoverageLevelOption from '@components/ui/CoverageLevelModal/CoverageLevelOption'

const OpticalLevel = ({
  control, watch, formState,
}: UseFormReturn<OnePageFormData>) => {
  const {
    t,
  } = useTranslation(['offers'])

  const {
    errors,
  } = formState

  const opticalLevelStatus = useGetButtonGroupStatus(errors.opticalLevel?.type)

  const coverageLevelOptions = options.map(option => ({
    icon: <CoverageLevelOption level={option} />,
    value: option.value,
  }))

  const opticalLevel = watch('opticalLevel')

  return (
    <div className='flex flex-col items-start gap-2 self-stretch'>
      <div className='flex items-center gap-[6px]'>
        <Icon name='visibility-on' size={20}
          className='fill-[#808284]'
        />
        <h4 className='text-base font-semibold leading-[25.6px] tracking-[-0.176px] text-neutral-400'>Optique</h4>
      </div>

      <div className='mt-2 w-full'>
        <Controller
          control={control}
          name='opticalLevel'
          render={({
            field: {
              value,
              ref,
              name,
              onChange,
              onBlur,
            },
          }) => (
            <ButtonGroup options={coverageLevelOptions}
              status={opticalLevelStatus} variant={ButtonGroupVariant.LIGHT}
              currentValue={value}
              ref={ref}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </div>

      <Caption variant={TextVariant.REGULAR}>{t(`offers:coverageLevelModal.optical.${opticalLevel}`)}</Caption>
    </div>
  )
}

export default OpticalLevel
