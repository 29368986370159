import {
  Modal,
} from '@selectra-it/selectra-ui'
import {
  FC,
  useState,
} from 'react'
import {
  useTransition,
  animated,
} from '@react-spring/web'
import {
  Route, Routes, useParams,
} from 'react-router-dom'
import {
  useDispatch,
} from 'react-redux'

import useGetUserFromEstimationIdRouteParam from '@hooks/offers/useGetUserFromEstimationIdRouteParam'
import useGetOffersByEstimationId from '@hooks/offers/useGetOffersByEstimationId'
import {
  LeadsourceOption, Owner,
} from '@root/domain/crm/Record'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  isModalOpen, resetTracking, SIXTY_SECOND_MODAL, toggleModal,
} from '@root/services/modalSlice'

import HelpRequestCallback from './HelpModal/HelpRequestCallback'
import Information from './SixtySecondCallbackModal/Information'

enum SixtySecondCallbackPages {
  INFORMATION = '/information',
  CALLBACK_REQUEST = '/callback-request',
}

const SixtySecondCallbackModal: FC = () => {
  const [location, setLocation] = useState<string>(SixtySecondCallbackPages.INFORMATION)
  const isOpen = useAppSelector(state => isModalOpen(state, SIXTY_SECOND_MODAL))
  const dispatch = useDispatch()
  const onAfterClose = () => {
    setLocation(SixtySecondCallbackPages.INFORMATION)
    dispatch(resetTracking())
  }

  const setIsOpen = () => {
    dispatch(toggleModal(SIXTY_SECOND_MODAL))
  }

  const transitions = useTransition(location, {
    from: {
      transform: 'translateX(10%)',
      opacity: 0,
    },
    enter: {
      transform: 'translateX(0%)',
      opacity: 1,
    },
  })

  const {
    fetchUser: {
      data,
    },
  } = useGetUserFromEstimationIdRouteParam()
  const params = useParams()
  const estimationId = params.estimationId ?? ''

  const {
    data: estimationData,
  } = useGetOffersByEstimationId(estimationId)

  const mainRecipient = estimationData?.estimation.adults.find(adult => adult.type === 'PRINCIPAL')

  return (
    <>
      <Modal open={isOpen} setOpen={setIsOpen}
        onAfterClose={onAfterClose}
      >
        {transitions((styles, location) => (
          <animated.div style={styles} className='flex grow flex-col gap-4 md:gap-6'>
            <Routes location={location}>
              <Route path={SixtySecondCallbackPages.INFORMATION} element={
                <Information setLocation={() => {
                  setLocation(SixtySecondCallbackPages.CALLBACK_REQUEST)
                }}
                />}
              />
              <Route path={SixtySecondCallbackPages.CALLBACK_REQUEST} element={
                <HelpRequestCallback user={data}
                  owner={Owner.INSURANCE_FRANCE_MANAGER} leadSourceOption={LeadsourceOption.COMPARATOR_HELP}
                  address={estimationData?.estimation.address}
                  mainRecipient={mainRecipient}
                />}
              />
            </Routes>
          </animated.div>
        ))}
      </Modal>
      {/* <Modal open={isOpen} setOpen={() => {}}>
        <div className='flex h-full flex-col gap-6'>
          <div className='flex flex-col items-center gap-4 overflow-y-auto'>
            <div className='flex flex-wrap content-center items-center gap-2 self-stretch fill-[#808284]'>
              <Icon name='phone' size={24} />
              <h3 className='text-[17px] font-semibold leading-[128%] tracking-[-0.221px] text-[#343535]'>Vous n&apos;arrivez pas à vous décider ?</h3>
            </div>
            <div className='text-neutral-400 text-ju'>
              <Text variant={TextVariant.BOLD} tag='span'>Appelez l’un de nos Experts</Text><Text variant={TextVariant.REGULAR} tag='span'> en Assurance pour comparer les garanties de plusieurs devis et prendre la bonne décision.</Text><Text variant={TextVariant.BOLD} tag='span'> Service gratuit.
              </Text>
            </div>

          </div>
        </div>
      </Modal> */}
    </>

  )
}

export default SixtySecondCallbackModal
