import {
  FC,
} from 'react'
import {
  Caption, TextVariant,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'

import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import Price from '@components/offers/Price'
import {
  insurerLogos,
} from '@root/domain/Insurer'
import Check from '@assets/icons/check.svg?react'
import Cross from '@assets/icons/cross.svg?react'
import {
  documentSort,
} from '@root/domain/Offer'

import SubscriptionButton from './Subscription/SubscriptionButton'
import DocumentList from './Documents/Horizontal/DocumentList'
import Step from './Level/Step'

import {
  OfferItemProps,
} from '.'

const OfferItemHorizontal: FC<OfferItemProps> = ({
  offer,
}) => {
  const {
    matchRate, monthlyPrice, title, insurerId, coverage,
  } = offer

  const {
    t,
  } = useTranslation(['offers', 'common'])

  const insurer = useAppSelector(state => selectInsurerById(state, insurerId))

  if (!insurer) {
    return null
  }

  const Logo = insurerLogos[insurer.id]

  return (
    <div className='border-neutral-100 bg-white rounded-3xl border'>
      <div className='flex self-stretch items-start flex-row px-4 py-4 gap-10'>
        <div className='flex flex-row w-full gap-10'>
          <div className='flex flex-col gap-2 grow min-w-[160px] max-w-[200px]'>
            <div className='flex gap-2 w-full'>
              <div className='flex flex-col grow items-start gap-2'>
                {typeof Logo === 'string'
                  ? <img src={Logo} className='h-8'/>
                  : <Logo height={32} /> }
                <p className='mt-1 font-semibold text-neutral-500 md:text-lg'>{title}</p>
              </div>
            </div>
            <p className='text-sm text-neutral-350 md:text-base'>
              Répond à <strong>{matchRate} %</strong> de vos besoins
            </p>
          </div>
          <div className='flex-1 flex gap-4'>
            <div className='flex flex-col text-neutral-350 gap-2'>
              {['consultation', 'hospitalization', 'dental', 'optical'].map(key => {
                return coverage[key] && (
                  <Caption key={key} variant={TextVariant.REGULAR}>{t(`offers:coverage_levels.${key}` as 'coverage_levels.consultation')}</Caption>
                )
              })}
              <div>
                {t('offers:alternativeMedicine')}
              </div>
            </div>
            <div className='flex grow flex-col  text-neutral-350 gap-2'>
              {['consultation', 'hospitalization', 'dental', 'optical'].map(key => {
                return coverage[key] && (
                  <div className='flex items-center' key={key}>
                    <Caption variant={TextVariant.REGULAR}><wbr /></Caption>
                    <Step level={coverage[key]} />
                  </div>
                )
              })}
              <div className='flex gap-1 items-center'>
                {offer.alternativeMedicine ? <><Check/> {t('common:included')} </> : <><Cross/> {t('common:not_included')} </>}
              </div>
            </div>
            {/* <ul className='mb-2 flex flex-col gap-2'>
              {['consultation', 'hospitalization', 'dental', 'optical'].map(key => {
                return coverage[key] && (
                  <Level
                    key={key}
                    level={coverage[key]}
                    label={key}
                    isIncluded={false}
                  />
                )
              })}
              <AlternativeMedicine alternativeMedicine={offer.alternativeMedicine} />
            </ul> */}
          </div>
        </div>
        <div className='w-full grow-0 flex flex-col gap-4 max-w-[175px]'>
          <div className='items-start flex'>
            <Price price={monthlyPrice} withDecimals={false} />
          </div>
          <SubscriptionButton offer={offer} />
        </div>
      </div>
      <DocumentList documents={offer.documents.toSorted(documentSort)} />
    </div>
  )
}

export default OfferItemHorizontal
