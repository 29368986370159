import {
  useState,
} from 'react'
import {
  Button, ButtonSize, ButtonType, Modal,
} from '@selectra-it/selectra-ui'
import {
  useParams,
} from 'react-router-dom'

import Icon from '@components/Icon'
import {
  readablePhoneNumber,
} from '@root/util/formatting'
import {
  LeadsourceOption, Owner, PhoneNumbers,
} from '@root/domain/crm/Record'
import HelpRequestCallback from '@components/ui/HelpModal/HelpRequestCallback'
import useGetUserFromEstimationIdRouteParam from '@hooks/offers/useGetUserFromEstimationIdRouteParam'
import useGetOffersByEstimationId from '@hooks/offers/useGetOffersByEstimationId'

export default function MoreOffers () {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const {
    fetchUser: {
      data,
    },
  } = useGetUserFromEstimationIdRouteParam()

  const params = useParams()
  const estimationId = params.estimationId ?? ''

  const {
    data: estimationData,
  } = useGetOffersByEstimationId(estimationId)

  const mainRecipient = estimationData?.estimation.adults.find(adult => adult.type === 'PRINCIPAL')

  const phoneNumber = PhoneNumbers[LeadsourceOption.COMPARATOR_HELP_CARD]

  return (
    <div className="py-6 sm:px-6 pb-4 sm:border-primary-400 border-0 sm:border-2 sm:rounded-xl bg-white flex flex-col gap-4">
      <div className="flex flex-wrap items-center justify-start gap-6 sm:gap-8">
        <div className="h-[32px]">
          <img src="/insurers/apivia-64.png" alt="APIVIA"
            className="h-full object-contain"
          />
        </div>

        <div className="h-[32px]">
          <img src="/insurers/acheel-64.png" alt="Acheel"
            className="h-full object-contain"
          />
        </div>

        <div className="h-[32px]">
          <img src="/insurers/allianz-64.png" alt="Allianz"
            className="h-full object-contain"
          />
        </div>

        <div className="h-[32px]">
          <img src="/insurers/april-64.png" alt="April"
            className="h-full object-contain"
          />
        </div>
      </div>
      <div className='flex flex-col sm:flex-row justify-between gap-6 sm:gap-10 items-center'>
        <div className="flex flex-col justify-between items-start">
          <div className="max-w-lg">
            <h2 className="text-lg font-medium text-gray-800 mb-2">
              Nous pouvons aussi vous proposer des devis chez ces 4 autres assureurs.
            </h2>

            <p className="text-gray-700">
              Le devis se fait par téléphone avec un conseiller Selectra Expert en Assurance (gratuit et sans
              engagement)
            </p>
          </div>
        </div>
        <div className="w-full grow-0 flex flex-col gap-4 sm:max-w-[175px]">
          <a className="sui:h-10 sui:px-4 sui:py-2 sui:gap-2 sui:rounded-lg sui:bg-secondary-400 sui:hover:bg-secondary-500 sui:hover:focus-visible:ring-secondary-500 sui:focus-visible:ring-2 sui:ring-offset-2 sui:focus-visible:ring-secondary-400 sui:outline-hidden sui:ring-offset-black sui:fill-white sui:text-white sui:rounded-full! sui:w-full  sui-parent sui:disabled:bg-neutral-100 sui:disabled:text-neutral-200 sui:disabled:outline-hidden  sui:justify-center sui:items-center sui:gap-2 sui:flex" href={`tel:+33${phoneNumber.substring(1)}`}>
            <Icon name="phone" size={16}
              className='inline-block'
            />
            <span className='sui:text-center  sui:text-base sui:leading-[124%] sui:font-bold'>
              {readablePhoneNumber(phoneNumber)}
            </span>
          </a>

          <Button
            type='button'
            variant={ButtonType.LIGHT}
            size={ButtonSize.MEDIUM}
            pill
            hasBorderColor
            label='Rappel gratuit'
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </div>
      <Modal open={isModalOpen} setOpen={setIsModalOpen}>
        <div className='flex grow flex-col gap-4  md:gap-6 md:p-2'>
          <HelpRequestCallback
            user={data}
            owner={Owner.INSURANCE_FRANCE_MANAGER}
            leadSourceOption={LeadsourceOption.COMPARATOR_HELP_CARD}
            address={estimationData?.estimation.address}
            mainRecipient={mainRecipient}
          />
        </div>
      </Modal>
    </div>
  )
}
