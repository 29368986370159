import {
  InputGroup,
  getErrorMessages,
  ErrorMessages,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  UseFormReturn,
} from 'react-hook-form'

import Profession from '@components/form/Profession'
import {
  useGetProfessionQuery,
} from '@root/services/profession'
import {
  WizardFormData,
} from '@root/domain/Wizard'

interface Props extends UseFormReturn<WizardFormData> {
  id?: number;
}

const ProfessionInformation = ({
  id = 0, control, formState,
}: Props) => {
  const {
    t,
  } = useTranslation(['wizard', 'validation'])
  const {
    data,
  } = useGetProfessionQuery()

  const {
    errors,
  } = formState

  const errorType = errors.adults?.[id]?.profession?.type

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.mustSelectProfession'),
  }, errorType)

  if (!data) {
    return null
  }

  const {
    items,
  } = data

  return (
    <div className='flex flex-col gap-6'>
      <InputGroup
        errors={inputErrors}
      >
        <Profession
          items={items}
          control={control}
          error={errorType}
          label="Profession ou activité"
          id={id}
        />
      </InputGroup>
    </div>
  )
}

export default ProfessionInformation
