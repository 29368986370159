import {
  InputGroup, getErrorMessages, ErrorMessages, DateInput,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  Controller, UseFormReturn,
} from 'react-hook-form'

import useGetInputStatus from '@hooks/useGetInputStatus'
import {
  isAtLeast18,
  isPastDate,
  isReasonableDate,
} from '@root/util/date'
import {
  WizardFormData,
} from '@root/domain/Wizard'

interface Props extends UseFormReturn<WizardFormData> {
  id?: number;
}

const BirthDate = ({
  id = 0, control, formState,
}: Props) => {
  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const {
    errors,
  } = formState

  const errorType = errors.adults?.[id]?.birthDate?.type

  const status = useGetInputStatus(errorType)
  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.birthDate.required'),
    atLeast18: t('validation:validation.form.birthDate.atLeast18'),
    reasonableDate: t('validation:validation.form.birthDate.reasonableDate'),
  }, errorType)

  return (
    <div className='flex flex-col gap-6'>
      <InputGroup description={t('wizard:wizard.steps.birthDate.description')}
        errors={inputErrors}
      >
        <Controller
          name={`adults.${id}.birthDate` as 'adults.0.birthDate'}
          control={control}
          rules={{
            required: true,
            validate: {
              reasonableDate: value => {
                if (!value) {
                  return true
                }

                return !!(isReasonableDate(value) && isPastDate(value))
              },
              atLeast18: value => {
                if (!value) {
                  return true
                }

                return !!isAtLeast18(value)
              },
            },
          }}
          render={renderParams => {
            const {
              field: {
                value,
                ref,
                name,
                onChange,
                onBlur,
              },
            } = renderParams

            return (
              <DateInput label={t('wizard:wizard.steps.birthDate.label')}
                value={value ? new Date(value) : null}
                name={name}
                status={status}
                onBlur={onBlur}
                onChange={date => {
                  onChange(date?.getTime())
                }}
                ref={ref}
              />
            )
          }}
        />

      </InputGroup>
    </div>
  )
}

export default BirthDate
