import {
  FC,
} from 'react'

import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import Price from '@components/offers/Price'
import {
  insurerLogos,
} from '@root/domain/Insurer'
import {
  documentSort,
} from '@root/domain/Offer'

import Level from './Level'
import SubscriptionButton from './Subscription/SubscriptionButton'
import AlternativeMedicine from './AlternativeMedicine'
import DocumentListItem from './Documents/Horizontal/DocumentListItem'

import {
  OfferItemProps,
} from '.'
const OfferItem: FC<OfferItemProps> = ({
  offer,
}) => {
  const {
    matchRate, monthlyPrice, title, insurerId, coverage,
  } = offer

  const insurer = useAppSelector(state => selectInsurerById(state, insurerId))

  if (!insurer) {
    return null
  }

  const Logo = insurerLogos[insurer.id]

  return (
    <div className='bg-white w-screen -ml-4-mobile sm:w-auto sm:ml-0'>
      <div className='flex self-stretch items-start flex-col px-4 pt-4 pb-6 gap-6'>
        <div className='flex flex-col w-full gap-4'>
          <div className='flex flex-col gap-2'>
            <div className='flex gap-2 w-full'>
              <div className='flex flex-col grow items-start gap-4'>
                {typeof Logo === 'string'
                  ? <img src={Logo} className='h-8'/>
                  : <Logo height={32} /> }
                <p className='mt-1 font-semibold text-neutral-500 md:text-lg'>{title}</p>
              </div>
              <Price price={monthlyPrice} />
            </div>
            <p className='text-sm text-neutral-350 md:text-base'>
              Répond à <strong>{matchRate} %</strong> de vos besoins
            </p>
          </div>
          <div>
            <ul className='mb-2 flex flex-col gap-2'>
              {['consultation', 'hospitalization', 'dental', 'optical'].map(key => {
                return coverage[key] && (
                  <Level
                    key={key}
                    level={coverage[key]}
                    label={key}
                    isIncluded={false}
                  />
                )
              })}
              <AlternativeMedicine alternativeMedicine={offer.alternativeMedicine} />
            </ul>
          </div>
        </div>
        <div className='flex flex-wrap gap-6'>
          {offer.documents.toSorted(documentSort).map(document => {
            return (
              <DocumentListItem key={document.id} document={document}/>
            )
          })}
        </div>
        <div className='w-full'>
          <SubscriptionButton offer={offer} />
        </div>
      </div>
    </div>
  )
}

export default OfferItem
