import {
  Avatar,
  AvatarSize,
  Button,
  ButtonSize,
  ButtonType,
  Caption,
  TextVariant,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'
import {
  useDispatch,
} from 'react-redux'
import {
  useTranslation,
} from 'react-i18next'

import {
  HELP_MODAL, toggleModal,
} from '@root/services/modalSlice'
import {
  LeadsourceOption, PhoneNumbers,
} from '@root/domain/crm/Record'
import {
  readablePhoneNumber,
} from '@root/util/formatting'
import Agent from '@assets/agents/picture-0.png'
import FranceFlag from '@assets/flag/france.svg?react'

const MobileStickyFooter: FC = () => {
  const dispatch = useDispatch()

  const {
    t,
  } = useTranslation('common')

  const phoneNumber = PhoneNumbers[LeadsourceOption.COMPARATOR_HELP_CARD]

  return (
    <div className="sticky lg:hidden bottom-0 w-full bg-white border-t p-4-mobile pb-2">
      <div className='container-fluid flex flex-col gap-2'>
        <Button variant={ButtonType.LIGHT} size={ButtonSize.MEDIUM}
          onClick={() => {
            dispatch(toggleModal(HELP_MODAL))
          }}
          label={readablePhoneNumber(phoneNumber)}
          iconLeft={() =>
            <Avatar size={AvatarSize.SMALL} status={true}
              avatar={() => <img src={Agent} className='rounded-4xl' />}
            />}
          iconRight={() => <FranceFlag className='h-4-mobile' />}
          hasBorderColor={true}
        />
        <div className='text-center'>
          <Caption variant={TextVariant.REGULAR} tag='span'>{t('cta_callback_text')}</Caption>
          <Caption variant={TextVariant.BOLD} tag='span'>&nbsp;· {t('free_service')}</Caption>
        </div>
      </div>
    </div>
  )
}

export default MobileStickyFooter
