import {
  getErrorMessages, ErrorMessages, InputGroup, RadioBoxContainer, RadioBox,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  UseFormReturn,
} from 'react-hook-form'

import useGetRadioStatus from '@hooks/useGetRadioStatus'
import {
  Gender,
} from '@root/domain/Gender'
import Icon from '@components/Icon'
import {
  WizardFormData,
} from '@root/domain/Wizard'

interface Props extends UseFormReturn<WizardFormData> {
  id?: number;
}

const GenderInformation = ({
  id = 0, register, formState,
}: Props) => {
  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const {
    errors,
  } = formState

  const errorType = errors.adults?.[id]?.gender?.type

  const status = useGetRadioStatus(errorType)

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.mustSelectInsuranceGender'),
  }, errorType)

  return (
    <div className='flex flex-col gap-6'>
      <InputGroup errors={inputErrors}>
        <RadioBoxContainer>
          <RadioBox
            id={`male-${id}`}
            item={{
              icon: () => (
                <Icon name='man' size={36}
                  className={'m-auto mb-2 block size-12'}
                />
              ),
              label: t('wizard:wizard.steps.insuranceGender.items.men'),
              value: Gender.MALE,
            }}
            {...register(`adults.${id}.gender` as 'adults.0.gender', {
              required: true,
            })}
            status={status}
            dataCy={'man'}
          />
          <RadioBox
            id={`female-${id}`}
            item={{
              icon: () => (
                <Icon name='woman' size={36}
                  className={'m-auto mb-2 block size-12'}
                />
              ),
              label: t('wizard:wizard.steps.insuranceGender.items.women'),
              value: Gender.FEMALE,
            }}
            {...register(`adults.${id}.gender` as 'adults.0.gender', {
              required: true,
            })}
            status={status}
            dataCy={'woman'}
          />
        </RadioBoxContainer>

      </InputGroup>
    </div>
  )
}

export default GenderInformation
