import {
  FC,
} from 'react'
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import {
  Button, ButtonSize, ButtonType, ErrorMessages, InputGroup, TextInput, getErrorMessages,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'

import MobileGray from '@assets/icons/mobile-gray.svg?react'
import Check from '@assets/icons/check.svg?react'
import {
  useCreateRecordMutation,
} from '@root/services/crm'
import {
  User,
} from '@root/domain/User'
import PhoneNumberInput from '@components/form/PhoneNumberInput'
import CallbackDateInput, {
  CallbackDate,
} from '@components/form/CallbackDateInput'
import CallbackTimeInput, {
  CallbackTime,
} from '@components/form/CallbackTimeInput'
import useGetInputStatus from '@hooks/useGetInputStatus'
import {
  Leadsource,
  LeadsourceOption, Name, Owner,
} from '@root/domain/crm/Record'
import {
  Adult, EstimationAddress,
} from '@root/domain/Estimation'
import {
  callbackDateNow,
} from '@root/domain/OfferMer'
import {
  formatCallbackDateTime,
} from '@root/util/date'

interface HelpRequestCallbackFormState {
  callbackTime: CallbackTime | null,
  callbackDate: CallbackDate,
  phoneNumber: {
    number: string,
  },
  firstName: string,
  lastName: string,
}

interface HelpRequestCallbackProps {
  user?: User,
  owner: Owner,
  leadSourceOption: LeadsourceOption,
  mainRecipient?: Adult,
  address?: EstimationAddress
}

const HelpRequestCallback: FC<HelpRequestCallbackProps> = ({
  user,
  owner,
  leadSourceOption,
  mainRecipient,
  address,
}) => {
  const [createRecord, result] = useCreateRecordMutation()
  const {
    t,
  } = useTranslation(['modal', 'wizard', 'validation'])

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm<HelpRequestCallbackFormState>({
    defaultValues: {
      callbackTime: null,
      callbackDate: callbackDateNow,
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      phoneNumber: {
        number: user?.phoneNumber ?? '',
      },
    },
  })

  const callbackDate = watch('callbackDate')

  const firstNameInputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errors.firstName?.type)

  const lastNameInputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errors.lastName?.type)

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addPhoneNumber'),
    phoneNumber: t('validation:validation.form.invalidPhoneNumber'),
  }, errors.phoneNumber?.number?.type)

  const dateInputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errors.callbackDate?.type)

  const dateTimeInputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errors.callbackTime?.type)

  const phoneNumberInputStatus = useGetInputStatus(errors.phoneNumber?.number?.type)
  const firstNameInputStatus = useGetInputStatus(errors.firstName?.type)
  const lastNameInputStatus = useGetInputStatus(errors.lastName?.type)

  const onSubmitHandler: SubmitHandler<HelpRequestCallbackFormState> = async ({
    callbackDate,
    callbackTime,
    firstName,
    lastName,
    phoneNumber,
  }) => {
    const date = formatCallbackDateTime(callbackDate, callbackTime)

    createRecord({
      interaction: {
        lead_source: Leadsource[leadSourceOption],
        name: Name.CALLBACK,
        owner,
        email: user?.email ?? '',
        origin_url: window.location.href,
        phone: phoneNumber.number,
        callback_date: date,
      },
      contact: {
        firstName,
        lastName,
        owner,
        lead_source: Leadsource[leadSourceOption],
        birthdate: mainRecipient?.birthDate ?? '',
        address: address?.formattedAddress ?? '',
      },
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <h3 className='mb-4 flex gap-2 font-semibold md:mb-6'>
        <MobileGray className='shrink-0'/>
        Rappel gratuit
      </h3>

      <ul className='mb-4 flex flex-col gap-2'>
        <li className='flex items-start gap-1'> <Check className='shrink-0'/> Un Conseiller Expert en Assurance répond à vos questions gratuitement</li>
        <li className='flex items-start gap-1'> <Check className='shrink-0'/> Rappel immédiat & gratuit</li>
      </ul>

      <div className='flex flex-col gap-6'>

        { !user?.firstName && <InputGroup errors={firstNameInputErrors}>

          <TextInput
            status={firstNameInputStatus}
            {...register('firstName', {
              required: true,
              minLength: 3,
              maxLength: 30,

            })}
            id='firstNameCallback'
            label='Prénom'

          />

        </InputGroup> }

        { !user?.lastName && <InputGroup errors={lastNameInputErrors}>

          <TextInput
            status={lastNameInputStatus}
            {...register('lastName', {
              required: true,
              minLength: 3,
              maxLength: 30,
            })}
            id='lastNameCallback'
            label='Nom'
          />

        </InputGroup> }

        <InputGroup errors={inputErrors}>
          <PhoneNumberInput<HelpRequestCallbackFormState>
            label={t('wizard:wizard.steps.phoneNumber.label')}
            name='phoneNumber.number'
            register={register}
            status={phoneNumberInputStatus}
          />
        </InputGroup>

        <InputGroup errors={dateInputErrors}>
          <Controller
            name='callbackDate'
            control={control}
            rules={{
              required: true,
            }}
            render={renderParams => {
              const {
                field: {
                  value,
                  ref,
                  name,
                  onChange,
                },
              } = renderParams
              return (
                <CallbackDateInput value={value} ref={ref}
                  name={name} onChange={onChange}
                  error={errors.callbackDate?.type}
                />
              )
            }}
          />
        </InputGroup>

        { callbackDate && callbackDate.id !== 'now' &&
          <InputGroup errors={dateTimeInputErrors}>
            <Controller
              name='callbackTime'
              control={control}
              rules={{
                required: true,
              }}
              render={renderParams => {
                const {
                  field: {
                    value,
                    ref,
                    name,
                    onChange,
                  },
                } = renderParams

                return (
                  <CallbackTimeInput value={value} ref={ref}
                    name={name} onChange={onChange}
                    error={errors.callbackTime?.type}
                  />
                )
              }}
            />
          </InputGroup>
        }
      </div>

      <div className='mt-6 fill-black text-black '>
        <Button
          type='submit'
          disabled={result.isLoading || result.isSuccess}
          label={result.isSuccess ? 'Rappel programmé !' : 'Me faire rappeler'}
          iconLeft={result.isSuccess ? Check : undefined}
          variant={result.isSuccess ? ButtonType.SUCCESS : ButtonType.PRIMARY}
          size={ButtonSize.MEDIUM}
          pill
        />
      </div>

    </form>
  )
}

export default HelpRequestCallback
