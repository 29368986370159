import {
  createSelector,
} from '@reduxjs/toolkit'

import {
  RootState,
} from '@root/store/Reducer'
import {
  Offer,
} from '@root/domain/Offer'

import {
  OfferFiltersState,
} from '../filterSlice'

import {
  BEST_PRICE,
  BEST_QUALITY,
  BEST_QUALITY_PRICE,
  OffersApi,
} from './index'

const selectOffersByEstimationIdBase = OffersApi.endpoints.getOffersByEstimationId.select

export const selectOffersForEstimationId = (estimationId: string, filters: OfferFiltersState) =>
  selectOffersByEstimationIdBase({
    estimationId,
    ...filters,
    alternativeMedicine: Boolean(filters.alternativeMedicine),
  })

export const selectOfferById = (estimationId: string, offerId: string) =>
  createSelector(
    (state: RootState) => selectOffersForEstimationId(estimationId, state.filter)(state as never),
    offers => offers.data?.items.find(offer => offer.id === offerId) || null
  )

export const selectTaggedOffers = (estimationId: string) => createSelector(
  (state: RootState) => selectOffersForEstimationId(estimationId, state.filter)(state as never),
  offers => {
    const itemsMap = new Map(offers.data?.items.map(offer => [offer.id, offer]))

    const sortedOffers = [
      itemsMap.get(offers.data?.tags[BEST_QUALITY] as string),
      itemsMap.get(offers.data?.tags[BEST_QUALITY_PRICE] as string),
      itemsMap.get(offers.data?.tags[BEST_PRICE] as string)
    ].filter(Boolean) as Offer[]

    return sortedOffers
  }
)
