import {
  InputGroup, getErrorMessages, ErrorMessages, TextInput,
} from '@selectra-it/selectra-ui'
import {
  useTranslation,
} from 'react-i18next'
import {
  UseFormReturn,
} from 'react-hook-form'

import useGetInputStatus from '@hooks/useGetInputStatus'
import {
  WizardFormData,
} from '@root/domain/Wizard'

const Children = ({
  register, formState,
}: UseFormReturn<WizardFormData>) => {
  const {
    t,
  } = useTranslation(['wizard', 'validation'])

  const {
    errors,
  } = formState

  const status = useGetInputStatus(errors?.children?.type)

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: t('validation:validation.form.addChildren'),
    min: t('validation:validation.form.addChildren'),
  }, errors?.amountOfChildren?.type)

  return (
    <div className='flex flex-col gap-6'>
      <InputGroup
        errors={inputErrors}
      >
        <TextInput label={t('wizard:wizard.steps.children.label')}
          status={status}
          {...register('amountOfChildren', {
            required: true,
            min: 1,
          })}
          type="number"
          dataCy={'children'}
        />

      </InputGroup>
    </div>
  )
}

export default Children
