import {
  apiService,
} from '@services/apiService'

export interface Payload {
  offerProvider: string
  offerProviderLogoUrl: string
  offerTitle: string
  offerDate: string
  offerPrice: string
  selectraCallbackRequest: string
  cgvLink?: string
  ipidLink?: string
  guarantiesLink?: string
  offerPageLink: string
  offerMerLink: string
}

export interface CreateMailingMutation {
  id: string
  email: string
  type: string
  payload: Payload
  unique_id?: string
}

export const insurerApi = apiService.injectEndpoints({
  endpoints: builder => ({
    createMailing: builder.mutation<void, CreateMailingMutation>({
      query: ({
        id, ...data
      }) => ({
        url: `api/mailing/${id}`,
        method: 'POST',
        body: {
          ...data,
          _method: 'PUT',
        },
      }),
    }),
  }),
})

export const {
  useCreateMailingMutation,
} = insurerApi
