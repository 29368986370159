import {
  Heading, HeadingVariant, Text, TextVariant,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'
import {
  Link,
} from 'react-router-dom'
import {
  useTranslation,
} from 'react-i18next'

import Icon from '@components/Icon'
import {
  Estimation,
} from '@root/domain/Estimation'

interface EditEstimationProps {
  estimation: Estimation
}

const getYearDifference = (d1: Date, d2: Date) => {
  return d2.getFullYear() - d1.getFullYear() - ((d2.getMonth() < d1.getMonth() || (d2.getMonth() === d1.getMonth() && d2.getDate() < d1.getDate())) ? 1 : 0)
}

const EditEstimation: FC<EditEstimationProps> = ({
  estimation,
}) => {
  const {
    t,
  } = useTranslation(['offers'])

  const text = estimation.adults.map(adult => {
    const age = getYearDifference(new Date(adult.birthDate), new Date())
    const pronoun = t(`offers:edit.${adult.type}`)
    return `${pronoun} (${age} ans)`
  }).join(', ')

  const hasChildren = estimation.children.length > 0 ? '_and_children' : ''
  const singleOrCouple = estimation.adults.length === 1 ? 'beneficiary_principal' : 'beneficiary_couple'

  return (
    <div className='flex flex-col items-start pt-6 xl:pt-16 pb-8 gap-2'>
      <Heading variant={HeadingVariant.H3}>
        Voici la liste des offres les plus adaptées à votre profil 👍
      </Heading>
      <div className='flex items-start self-stretch gap-2 text-[#343535]'>
        <div>
          <Icon name={`${singleOrCouple}${hasChildren}`} className='fill-[#808284]'
            size={24}
          />
        </div>
        <Text variant={TextVariant.REGULAR}>
          {text}
        </Text>
        <Link to='/recherche' className='text-primary-400'>
          <Text variant={TextVariant.UNDERLINED}>
            Modifier
          </Text>
        </Link>
      </div>
    </div>
  )
}

export default EditEstimation
