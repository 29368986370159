export default {
  validation: {
    form: {
      plateNumberUnknown: 'Cliquez sur “Je ne l’ai pas” si vous ne connaissez pas le numéro de plaque d’immatriculation.',
      boolean: {
        required: 'Sélectionnez une option ci-dessus afin de poursuivre.',
      },
      addCirculationDate: 'Veuillez saisir the date of putting into circulation to continue in the ci-dessus.',
      mustSelectAnOption: 'Merci de sélectionner une option ci-dessus afin de continuer',
      mustSelectBeneficiary: 'Sélectionnez une option parmi la liste afin que je crée des devis chez chaque assureur.',
      mustSelectInsuranceGender: 'Vous devez sélectionner votre civilité.',
      birthDate: {
        required: 'Veuillez saisir votre date de naissance dans le champ ci-dessus afin de poursuivre.',
        atLeast18: 'Vous avez moins de 18 ans selon votre date de naissance. Les assureurs ne peuvent pas fournir de devis aux mineurs.',
        reasonableDate: 'L\'année de votre date de naissance semble incorrecte.',
      },
      mustSelectProfession: 'Sélectionnez une profession ou activité dans le menu déroulant afin de poursuivre. Cette information est prise en compte par les assureurs.',
      mustSelectSocialRegime: 'Sélectionnez une option dans le menu déroulant afin de poursuivre. Cette information est prise en compte par les assureurs.',
      addChildren: "Veuillez saisir le nombre d'enfants dans le champ ci-dessus.",
      addPostalCode: 'Veuillez saisir votre code postal dans champ ci-dessus afin de poursuivre. Les assureurs ne demandent pas l’adresse complète. Ex : 75000 Paris',
      insuranceDate: {
        required: 'Veuillez saisir votre date de debut de contrat dans champ ci-dessus afin de poursuivre.',
        reasonableDate: 'Veuillez saisir une date valide qui est dans le futur mais ne dépasse pas un an à partir d\'aujourd\'hui.',
      },
      addLevels: 'Sélectionnez un niveau ci-dessus afin de poursuivre vers la page de comparaison.',
      addFirstName: 'Les assureurs ajoutent vos coordonnées sur les devis personnalisés, merci de les renseigner.',
      addEmail: 'Veuillez renseigner une adresse email valide afin de poursuivre.',
      addChildrenBirthDate: 'Veuillez saisir votre date de naissance dans champ ci-dessus afin de poursuivre. Ex : 31/01/2024',
      addPhoneNumber: 'Veuillez renseigner un numéro de téléphone valide afin de poursuivre.',
      invalidPhoneNumber: 'Le numéro de téléphone semble être invalide. Veuillez vérifier le format.',
      toc: 'Veuillez cocher la case pour poursuivre.',
    },
  },
}
