import {
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  Avatar,
  AvatarSize,
  Heading, HeadingVariant,
  Text,
  TextVariant,
} from '@selectra-it/selectra-ui'

import SubscriptionModal from '@components/ui/SubscriptionModal/SubscriptionModal'
import EditEstimation from '@components/offers/EditEstimation'
import useGetOffersByEstimationId from '@hooks/offers/useGetOffersByEstimationId'
import {
  useTaggedOffers,
} from '@hooks/offers/useTaggedOffers'
import useOpenSixtySecondModal from '@hooks/offers/useOpenSixtySecondModal'

import Offers from './Offers'
import LoadingPage from './LoadingPage/LoadingPage'
import OfferFilters from './OfferFilters'
import FeaturedOffer from './OfferItem/Variants/FeaturedOffer'

const OffersPage = () => {
  const [searchParams] = useSearchParams()
  const params = useParams()
  const estimationId = params.estimationId ?? ''

  const {
    data,
  } = useGetOffersByEstimationId(estimationId)
  useOpenSixtySecondModal()

  const taggedOffers = useTaggedOffers(estimationId)

  return (
    <div id="offers-page">
      <div className='pt-12 pb-8' id="offers-page-header">
        <div className='container-fluid mx-auto flex flex-col gap-2'>
          <div className='flex items-center gap-2 self-stretch text-[#343535]'>
            <Avatar status={false} size={AvatarSize.LARGE} />
            <Heading variant={HeadingVariant.H3}>
              Choix Selectra
            </Heading>
          </div>
          <Text variant={TextVariant.REGULAR}>
            Ces trois offres sont triées par nos experts afin de vous aider à faire un choix
          </Text>
        </div>
      </div>

      <div className='container-fluid'>
        <div className='overflow-x-scroll w-full items-start gap-8 hidden md:flex justify-between'>
          {taggedOffers.map(offer => (
            <div key={offer.id} className='basis-0 min-w-[327px] grow self-stretch'>
              <FeaturedOffer offer={offer} />
            </div>
          ))}
        </div>

      </div>

      <div className='container-fluid mx-auto hidden md:block'>
        {data?.estimation && <EditEstimation estimation={data?.estimation} />}
      </div>

      <div className='container-fluid mx-auto pb-12 md:pb-12'>
        <div className='grid w-full grid-cols-16 xl:gap-[30px]'>
          <div className='relative hidden xl:col-span-5 xl:block'>
            <div className='sticky top-20'>
              <OfferFilters />
            </div>
          </div>
          <div className='col-span-16 xl:col-span-11'>
            <Offers />
          </div>
        </div>
      </div>
      {(searchParams.get('preload')) && <LoadingPage />}
      <SubscriptionModal />
    </div>
  )
}

export default OffersPage
